import React, { useEffect, useState } from 'react';
import "../CSS/Titlebar.css";
import "../CSS/CaseList.css";
import Button from "./Button";
import { Case, Welcome, Appointment } from './casetypes';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../Auth/authConfig';
import LoadingCard from "./LoadingCard";
import apiURL from "../API/requests";
import { useParams, useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TtyIcon from '@mui/icons-material/Tty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
  cases: Case[];
}

  const calculateAge = (dateOfBirthString: string): number => {
    const today = new Date();
    const birthDate = new Date(dateOfBirthString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const formatDateTime = (dateTimeString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

const DashCaseDetails: React.FC<Props> = ({ cases }) => {
    const { caseID } = useParams<{ caseID: string }>();
    const [error, setError] = useState<string | null>(null);
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();
    const [selectedCase, setSelectedCase] = useState<Case | null>(null);

    const [activeCaseId, setActiveCaseId] = useState<string | null>(null);
    const [isVisible, setIsVisible] = useState(true);
    const [expandedEscalationIndex, setExpandedEscalationIndex] = useState<number | null>(null);
    const [expandedSummaryIndex, setExpandedSummaryIndex] = useState<number | null>(null);
    const [expandedTaskIndex, setExpandedTaskIndex] = useState<number | null>(null);


    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };

      const toggleExpand = (index: number) => {
        setExpandedEscalationIndex(expandedEscalationIndex === index ? null : index);
      };
    
      const toggleExpandSummary = (index: number) => {
        setExpandedSummaryIndex(expandedSummaryIndex === index ? null : index);
      };
    
      const toggleExpandTask = (index: number) => {
        setExpandedTaskIndex(expandedTaskIndex === index ? null : index);
      };
          
  return (
    <div>
        {cases.length > 0 ? (
        cases.map((cases) => {
          if (cases.caseId === caseID) {
            return (
            <>

                <div className="titlebar">              
                    <div className="Title">
                        <button onClick={() => navigate('/grid-dash')} style={{border:"none", padding:"0", width:"100%"}}>
                            <Button style="Back" icon="back" />
                        </button>
                        
                    </div>
                    <div className='case-name' style={{margin:"12px"}}>{cases.patient.lastName}, {cases.patient.firstName}</div>          
                    <div className="actions">
                        <div className="actionGRP">
                        <button style={{ border: "none", padding: "0" }} onClick={() => navigate('/add-new-case')}><Button Label="Add New Case" style="Default" icon="plus" /></button>
                        </div>
                    </div>
                </div>
                <div className='grid-case-details'>
                    <div className='list-patient-details'>
                        <div className="list-documents">
                            <div><b>{cases.name}</b></div>
                            <div>{formatDateTime(cases.updatedAt)}</div>                   
                        </div>
                        <div className="info">
                            <Button Label="Edit Case Details" style="Ghost" icon="right" />
                            <button onClick={toggleVisibility} style={{border:"none", width: "100%", margin:"0", padding:"0"}}>
                            <Button Label="Case Interactions" style="Default" icon="right"/></button>
                        </div>
                        <div className="info">
                            <div className="blocks">
                                <div className="blockHead">Date of Birth</div>
                                <div className="blockBody">{formatDate(cases.patient.dob)}</div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">Contact Details</div>
                                <div className="blockBody">{cases.patient.mobilePhoneNumber ? cases.patient.mobilePhoneNumber : cases.patient.phoneNumber}</div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">Discharge Provider</div>
                                <div className="blockBody">{cases.provider.title} {cases.provider.name}</div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">Start Date</div>
                                <div className="blockBody">{formatDateTime(new Date(cases.startDate).toLocaleString())}</div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">End Date</div>
                                <div className="blockBody">{formatDateTime(new Date(cases.endDate).toLocaleString())}</div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">Appointments</div>
                                <div className="blockBody">
                                {
                                    (cases.appointments && cases.appointments.length > 0) ? 
                                    cases.appointments.map((appointment) => (
                                    <div key={appointment.appointmentId}>
                                        <div>{appointment.title} </div>{formatDateTime(new Date(appointment.date).toLocaleString())}
                                    </div>
                                    ))
                                    : 
                                    "No appointment data available"
                                }
                                </div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">Medications</div>
                                <div className="blockBody">
                                {
                                    (cases.medications && cases.medications.length > 0) ? 
                                    cases.medications.map((medication, indexx) => (
                                        <div key={indexx}>
                                            <div>{medication.name} - {medication.dose}</div>
                                            <div>{medication.schedule}</div>
                                        </div>
                                    )) 
                                    : 
                                    "No medication data available" 
                                }
                                </div>
                            </div>
                            <div className="blocks">
                                <div className="blockHead">Case ID</div>
                                <div className="blockBody">{cases.caseId}</div>
                            </div>
                        </div>              
                    </div>
                    <div className='escalationgrp'>
                        <div className="main-head">
                            <div className="title">
                            <ErrorIcon /> Escalations
                            </div>
                        </div>
                        {cases.escalations ? (
                            cases.escalations.map((escalation, index) => (
                            <div className='esca-mapped' key={index}>
                                <div className="escalations">
                                <div className={`healthcase-head ${expandedEscalationIndex === index ? 'expand' : 'collapse'}`}>
                                <div className="left-esca">
                                    <div className="head-esca">
                                        <div className="Label-esca">{escalation.taskGraphDescription}</div>
                                        <div className="date-esca">
                                        <div style={{ width: "Auto" }}>
                                            <Button Label={escalation.severity} style={escalation.severity} icon={escalation.severity} />
                                        </div>
                                        {(!escalation.resolvedAt) ? 
                                        <div>
                                            Created Date: {formatDateTime(new Date(escalation.createdAt).toLocaleString())}
                                        </div> 
                                        : 
                                        <div>
                                        Resolved Date: {formatDateTime(new Date(escalation.resolvedAt).toLocaleString())}
                                        </div>
                                        }
                                        </div>
                                    </div>
                                    </div>
                                    <div className="right-esca" onClick={() => toggleExpand(index)}>
                                    {
                                        expandedEscalationIndex === index ? 
                                        <div style={{ cursor: "pointer" }}>
                                            <KeyboardArrowUpIcon />
                                        </div>
                                        : 
                                        <div style={{ cursor: "pointer" }}>
                                            <KeyboardArrowDownIcon />
                                        </div>
                                    }                          
                                    </div>
                                </div>
                                {expandedEscalationIndex === index && (
                                        <div className="suggestion">
                                            <div className="lbel">{escalation.taskGraphName}</div>
                                            <div className="esc-content" style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <div>
                                                <b>Type: </b> {escalation.type}
                                            </div>
                                            <div>
                                                <b>{escalation.description}</b>
                                            </div>
                                            </div>
                                        </div>
                                        )
                                }                      
                                {escalation.createdAt}
                                {escalation.resolvedAt}
                                </div>
                            </div>
                            ))
                        ) : (
                            <div className='nullValue'>No escalations available</div>
                        )}                    
                    </div>
                    <div className={`summary ${isVisible ? 'visible' : 'hidden'}`}>
                        <div className="main-head">
                            <div className="title">
                            <TtyIcon /> Case Interactions
                            </div>
                            <button onClick={toggleVisibility} style={{border:"none", margin:"0", padding:"0"}}>
                            <div><Button Label="" style="Round" icon="closed"  onClick={toggleVisibility}/></div></button>
                        </div>
                        {cases.caseInteractions && cases.caseInteractions.length > 0 ? (
                            cases.caseInteractions
                            .sort((a, b) => new Date(b.attemptDate).getTime() - new Date(a.attemptDate).getTime())
                            .map((interaction, index) => (
                            <div className='summaryGRP' key={index}>
                                <div className={`summary-head ${expandedSummaryIndex === index ? 'summary-expand' : 'summary-collapse'}`} onClick={() => toggleExpandSummary(index)}>
                                <div className="left-summary">
                                    {expandedSummaryIndex === index ? <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> : <KeyboardArrowDownIcon style={{ cursor: "pointer" }} />}
                                </div>
                                <div className="title-time">
                                    <div className='summary-label-name'>Attempt {(cases.caseInteractions.length) - index}</div>
                                    <div>
                                    {formatDateTime(new Date(interaction.attemptDate).toLocaleString())}
                                    </div>
                                </div>
                                <div style={{ width: "Auto" }}>
                                    <Button Label={interaction.caseState} style={interaction.caseState} icon={interaction.caseState} />
                                </div>
                                </div>
                                {expandedSummaryIndex === index && (
                                <div className="summary-content-accord">
                                    {interaction.taskSummary.map((task, taskIndex) => (
                                    <div className='taskGRP' key={taskIndex}>
                                        <div className='summary-head'>
                                        <div className="title-time">
                                            <div className='summary-label-name'>{task.goal}</div>
                                        </div>
                                        <div key={taskIndex}>
                                            {(task.status === 'COMPLETED') ? <CheckCircleOutlineIcon style={{color: '#28a745'}}/> : <HighlightOffIcon style={{color: 'RED'}}/>}
                                        </div>
                                        </div>
                                        {(task.escalation.length > 0) ? 
                                        <div className="suggestion" style={{border:"none"}}>
                                        <div className="lbel">
                                            <div className='esc-content'>
                                            {task.escalation}
                                            </div>
                                        </div>
                                        </div>
                                        : 
                                        ""
                                        }
                                    </div>
                                    ))}
                                </div>
                                )}
                            </div>
                            ))
                        ) : (
                        <div className='nullValue'>No case interactions found.</div>
                        )}
                        </div>                    
                </div>              
            </>   
            );
          }
          return null;
        })

      ) : (
      <div className='loading'><LoadingCard Message={"Almost Done"} Helper={"Loading Case Details."} /></div>
      )}
    </div>
  );
};

export default DashCaseDetails;
