import React, { useState } from 'react';
import "../CSS/Titlebar.css";
import { Case, State } from './casetypes';
import "../CSS/GridDash.css";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DraftsIcon from "@mui/icons-material/Drafts";
import Button from "./Button";
import InProgress from "./InProgress";
import Closed from "./Closed";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import LoadingCard from "./LoadingCard";
import DraftCases from './DraftCases';

interface Props {
  user: string;
  cases: Case[];
  draft: Case[];
}

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Poppins',
    },
  },
});

const GridDash: React.FC<Props> = ({ user, cases, draft }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false); // State for toggle switch
  const [sortByDateAsc, setSortByDateAsc] = useState(true); // State for sorting direction
  const [showDraft, setShowDraft] = useState(false); // State for showing/hiding draft

  const getStatePriority = (state: State) => {
    switch (state) {
      case State.Critical:
        return 1;
      case State.Active:
        return 2;
      case State.Completed:
        return 3;
      case 'CANCELLED':
        return 4;
      default:
        return 5;
    }
  };

  // Function to sort cases by date
  const sortCasesByDate = (cases: Case[]) => {
    return cases.slice().sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return sortByDateAsc ? dateA - dateB : dateB - dateA; // Toggle sorting direction
    });
  };

  // Function to sort cases by date
  const sortDraftByDate = (draft: Case[]) => {
    return draft.slice().sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return sortByDateAsc ? dateA - dateB : dateB - dateA; // Toggle sorting direction
    });
  };

  // Handler for toggle switch change
  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };

  // Handler for sorting toggle change
  const handleSortToggleChange = () => {
    setSortByDateAsc(!sortByDateAsc);
  };

  // Handler for draft toggle change
  const handleDraftToggleChange = () => {
    setShowDraft(!showDraft);
  };

  const sortedCasesArray = isChecked ? sortCasesByDate(cases) : cases.sort((a, b) => getStatePriority(a.state) - getStatePriority(b.state));
  const sortedDraftArray = isChecked ? sortDraftByDate(draft) : draft.sort((a, b) => getStatePriority(a.state) - getStatePriority(b.state));

  const handleSwapVertIconClick = () => {
    setSortByDateAsc(!sortByDateAsc);
  };

  return (
    <div style={{ display: "block", marginBottom: "12px" }}>
      <div className="titlebar" style={{ marginBottom: "12px" }}>
        <div className="Title">Welcome, {user}!</div>
        <div className="actions">
          <div className="toggleGRP">
            <ThemeProvider theme={theme}>
              <Tooltip title="Sort Cases by Date or Severity" placement="top" arrow>
                <div className="togglespace" onClick={handleSortToggleChange}>
                  <FormControlLabel
                    control={<IOSSwitch checked={isChecked} onChange={handleToggleChange} sx={{ m: 1 }} />}
                    label="Date and Severity"
                  />
                  <button style={{ border: "none", padding: "0" }} onClick={handleSwapVertIconClick}><SwapVertIcon className="sortHover" /></button>
                </div>
              </Tooltip>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Tooltip title="Show Draft Cases" placement="top" arrow>
                <div className="togglespace">
                  <FormControlLabel
                    control={<IOSSwitch checked={showDraft} onChange={handleDraftToggleChange} sx={{ m: 1 }} />}
                    label="Draft"
                  />
                </div>
              </Tooltip>
            </ThemeProvider>
          </div>
          <div className="actionGRP">
            {/* <Button style="Alt-Ghost" icon="snapshot" />
            <Button style="Alt-Ghost" icon="close" /> */}
            <div style={{ border: "none", padding: "0" }} onClick={() => navigate('/list-dash')}>
              <Button style="Alt-Ghost" icon='list' />
            </div>
            <div style={{ border: "none", padding: "0" }} onClick={() => navigate('/add-new-case')}><Button Label="Add New Case" style="Default" icon="plus" /></div>
          </div>
        </div>
      </div>
      <div className="grid-content">
        <div className="maxValue container">
          <div className="main-head">
            <div className="title">
              <ChecklistIcon />
              In Progress
            </div>
          </div>
          {cases ? <InProgress jsonData={{ cases: sortedCasesArray }} /> : <div style={{ width: "98%" }}><LoadingCard Message={"Almost Done"} Helper={"Loading In Progress Cases."} /></div>}
        </div>
        {showDraft && (
          <div className="minValue container">
            <div className="main-head">
              <div className="title">
                <DraftsIcon />Draft
              </div>
              <div><Button Label="" style="Round" icon="closed" /></div>
            </div>
            {cases ? <DraftCases jsonData={{ draft: sortedDraftArray }} /> : <div style={{ width: "98%" }}><LoadingCard Message={"Almost Done"} Helper={"Loading In Progress Cases."} /></div>}
          </div>
        )}
        <div className="minValue container">
          <div className="main-head">
            <div className="title">
              <CheckCircleIcon />Closed
            </div>
            <div><Button Label="" style="Round" icon="closed" /></div>
          </div>
          <div className="cases">
            {cases ? <Closed jsonData={{ cases: sortedCasesArray }} /> : <p>Loading...</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4fbc9c',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default GridDash;
