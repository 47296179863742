import PersonNote from "../Images/Person Note.png";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "../CSS/EmptyCard.css";

export const OPnotSelec = () => {
  return (

      <div className="EmptyCard">
          <div className="Helpbar">
            <div className="help iconSizeInContent">
              <HelpOutlineOutlinedIcon />
            </div>
          </div>
          <img className="PersonNote" src={PersonNote} alt="Logo" />
          <div className="Heading2">
            You have to select the care plan first to add a new case.
          </div>
          <div className="helper">
            The careplan dropdown consists of several outcome plan and it is important to select one to continue for adding new case details. 
          </div>
        </div>

    
  );
}
export default OPnotSelec;
