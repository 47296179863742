import React from 'react';
import '../CSS/DateInput.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import "react-datepicker/dist/react-datepicker.css";

interface DateInputProps {
  label: string;
  date: string;
  setDate: (date: string) => void;
  errorMessage?: string; // Add this prop to handle error messages
}

const DateInput: React.FC<DateInputProps> = ({ label, date, setDate, errorMessage }) => {
  const dateInputRef = React.useRef<HTMLInputElement>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    const formattedDate = selectedDate.toISOString();
    setDate(formattedDate);
  };

  const handleIconClick = () => {
    dateInputRef.current?.showPicker();
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    return date.toISOString().substring(0, 10);
  };

  return (
    <div className="date-input-container">
      <label htmlFor="date" className="date-label">{label}</label>
      <div className="date-input-wrapper">
        <input
          type="date"
          id="date"
          name="date"
          value={formatDate(date)}
          onChange={handleDateChange}
          className="date-input"
          ref={dateInputRef}
        />
        <span className="calendar-icon" onClick={handleIconClick}>
          <CalendarMonthIcon />
        </span>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display error message */}
    </div>
  );
};

export default DateInput;
