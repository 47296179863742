import "../CSS/Button.css";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DraftsIcon from "@mui/icons-material/Drafts";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import UpdateIcon from '@mui/icons-material/Update';

type ButtonProps = {
  Label?: string
  style?: string
  icon?: string
  onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
  switch(props.icon) {
    
    case "DRAFT": return (<><button className='Draft'><DraftsIcon className="icon transp"/><div>Draft</div></button></>)
    case "CANCELLED": return (<><button className='Canceled'><CancelIcon className="icon transp"/><div>Canceled</div></button></>)
    case "COMPLETED": return (<><button className='Complete'><CheckCircleIcon className="icon transp"/><div>Completed</div></button></>)
    case "MODERATE": return (<><button className='Moderate'><ErrorOutlineIcon className="icon transp"/><div>Moderate</div></button></>)
    case "ACTIVE": return (<><button className='Active'><LightbulbIcon className="icon transp"/><div>Active</div></button></>)
    
    case "CRITICAL": return (<><button className='Critical'><ErrorOutlineIcon className="icon transp"/><div>Critical</div></button></>)
    case "edit": return (<><button className={props.style}><EditIcon className="icon transp"/>{props.Label}</button></>)
    case "closed": return (<><button className={props.style}>{props.Label}<CloseIcon className="icon transp"/></button></>)
    case "right": return (<><button className={props.style}>{props.Label}<ChevronRightTwoToneIcon className="transp"/></button></>)
    case "back": return (<><button className={props.style}>{props.Label}<ArrowBackIosIcon className="icon transp"/></button></>)
    case "update": return (<><button className={props.style}>{props.Label}<UpdateIcon className="icon transp"/></button></>)
    case "power": return (<><button className={props.style}>{props.Label}<PowerSettingsNewIcon className="transp"/></button></>)
    case "plus": return (<><button className={props.style}><AddIcon className="transp"/>{props.Label}</button></>)
    case "phone": return (<><button className={props.style}>{props.Label}<PhoneForwardedIcon className="transp"/></button></>)
    case "grid": return (<><Tooltip title="Grid View" placement="top" arrow><button className={props.style} style={{width:"24px"}}><GridViewIcon className="transp"/>{props.Label}</button></Tooltip></>)
    case "list": return (<><Tooltip title="List View" placement="top" arrow><button className={props.style} style={{width:"24px"}}><FormatListBulletedIcon className="transp"/>{props.Label}</button></Tooltip></>)
    case "close": return (<><Tooltip title="Closed Cases" placement="top" arrow><button className={props.style} style={{width:"24px"}}><DoDisturbOnOutlinedIcon className="transp"/>{props.Label}</button></Tooltip></>)
    case "snapshot": return (<><Tooltip title="Snapshot" placement="top" arrow><button className={props.style} style={{width:"24px"}}><ManageHistoryOutlinedIcon className="transp"/>{props.Label}</button></Tooltip>
</>)
    
    default: return (<><button className={props.style}>{props.Label}</button></>)
  }
}
export default Button;
