import React, { useState, useEffect } from 'react';
import Button from "./Button";
import "../CSS/CaseList.css";
import { Case, State } from './casetypes';
import CaseNotSelected from "./CaseNotSelected";
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TtyIcon from '@mui/icons-material/Tty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import apiURL from "../API/requests";
import { useNavigate } from 'react-router-dom';
import OkInfo from "./OkInfo";

interface Props {
  jsonData?: {
    cases: Case[];
  };
}

const CaseList: React.FC<Props> = ({ jsonData }) => {
  const navigate = useNavigate();
  const casesArray: Case[] = jsonData ? jsonData.cases : [];

  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const [activeCaseId, setActiveCaseId] = useState<string | null>(null);
  const [expandedEscalationIndex, setExpandedEscalationIndex] = useState<number | null>(null);
  const [expandedSummaryIndex, setExpandedSummaryIndex] = useState<number | null>(null);
  const [expandedTaskIndex, setExpandedTaskIndex] = useState<number | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isInfoModalOpen, setInfoModalModalOpen] = useState(false);
  const [isOkInfoOpen, setIsOkInfoOpen] = useState<boolean>(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as Element).closest('.CaseList') === null) {
        if (activeCaseId) {
          setActiveCaseId(activeCaseId);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeCaseId]);

  const calculateAge = (dateOfBirthString: string): number => {
    const today = new Date();
    const birthDate = new Date(dateOfBirthString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const getStatePriority = (state: State) => {
    switch (state) {
      case State.Critical:
        return 1;
      case State.Active:
        return 2;
      case State.Completed:
        return 3;
      case 'CANCELLED':
        return 4;
      default:
        return 5;
    }
  };

  const sortedCasesArray = casesArray.sort((a, b) => getStatePriority(a.state) - getStatePriority(b.state));
  // console.log(sortedCasesArray);

  const formatDateTime = (dateTimeString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Set default caseData[0] (or casesArray[0]) on component mount
  useEffect(() => {
    if (casesArray.length > 0) {
      setActiveCaseId(casesArray[0].caseId);
      setSelectedCase(casesArray[0]);
    }
  }, [casesArray]);

  const handleCaseClick = (caseData: Case) => {
    if (activeCaseId === caseData.caseId) {
      setActiveCaseId(null);
      setSelectedCase(null);
    } else {
      setActiveCaseId(caseData.caseId);
      setSelectedCase(caseData);
    }
  };

  const toggleExpand = (index: number) => {
    setExpandedEscalationIndex(expandedEscalationIndex === index ? null : index);
  };

  const toggleExpandSummary = (index: number) => {
    setExpandedSummaryIndex(expandedSummaryIndex === index ? null : index);
  };

  const toggleExpandTask = (index: number) => {
    setExpandedTaskIndex(expandedTaskIndex === index ? null : index);
  };
  
  const handleEditCaseClick = (caseId: string) => {
    navigate(`/edit-case/${caseId}`);
  };

const getToken = async (): Promise<string> => {
        
  try {
    const token = localStorage.getItem("token") || "";
    return token;
  } catch (error) {
    const token = localStorage.getItem("token") || "";
    return token;
  }
};

const handleOkInfo = () => {
  window.location.reload();
};

const closeokInfo = () => {
  setInfoModalModalOpen(false);
};

const handleLaunchCaseClick = async (caseId: string) => {
  try {
    const token = await getToken();
    const url = `${apiURL.postCase}/${caseId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ state: 'ACTIVE' }),
    });

    if (response.ok) {
          // On success
          setSuccessMessage('The case has been successfully launched!');
          setErrorMessage(null); // Clear previous error
    } else {
          // On failure
          setErrorMessage('Failed to change the case state. Please try again.');
          setSuccessMessage(null); // Clear previous success message
    }
  } catch (error) {
          // On failure
          setErrorMessage('Failed to connect to server. Please try loging in again.');
          setSuccessMessage(null); // Clear previous success message
  } finally {
            setIsOkInfoOpen(true); // Open the modal regardless of success or failure
  }
};

  return (
    <div className="ListView">
      <div className="caseListCard sidebar">
        {casesArray.length > 0 ? (
          sortedCasesArray && sortedCasesArray.map((caseData) => (
            <button
              className={`CaseList ${activeCaseId === caseData.caseId ? 'active' : ''}`}
              key={caseData.caseId}
              onClick={() => handleCaseClick(caseData)}
            >
              <div className="nameAgeGRP">
                <div className="list-patient-name">
                  <b>{caseData.patient ? `${caseData.patient.lastName}, ${caseData.patient.firstName}` : "Information Unavailable"}</b>
                </div>
                <div className="ageGender">
                  <b>{caseData.patient ? `${caseData.patient.gender[0]} ${calculateAge(caseData.patient.dob)}`:"Information Unavailable"}</b>
                </div>
              </div>
              <div className="Severity">
                <div style={{ width: "Auto" }}>
                  <Button Label={caseData.state} style={caseData.state} icon={caseData.state} />
                </div>
                <div className="lastUpdated">
                  {formatDateTime(caseData.updatedAt)}
                </div>
              </div>
            </button>
          ))
        ) : (
          <div className="CaseEmpty">
            Loading..
          </div>
        )}
      </div>
      <div className="details">
        {/* {selectedCase ? (
          <>
            <div className='list-patient-details'>
              <div className="info" style={{color:"#106967", fontSize:"16px"}}>
              <b>{selectedCase.patient.lastName}, {selectedCase.patient.firstName}</b>
              </div>
              <div className="list-documents">
                <div><b>{selectedCase.name}</b></div>
                <div>{formatDateTime(selectedCase.updatedAt)}</div>
              </div>
              <div className="info">
                {(selectedCase.state === "DRAFT")? 
                  <div style={{border:"none", width: "100%", margin:"0", padding:"0"}} onClick={() => handleLaunchCaseClick(selectedCase.caseId)}>
                  <Button Label="Launch Case" style="Ghost" icon="right" />
                </div>
                :
                ""
                }
                
                <div style={{border:"none", width: "100%", margin:"0", padding:"0"}} onClick={() => handleEditCaseClick(selectedCase.caseId)}>
                  <Button Label="Edit Case Details" style="Ghost" icon="right" />
                </div>
                <div onClick={toggleVisibility} style={{border:"none", width: "100%", margin:"0", padding:"0"}}>
                <Button Label="Case Interactions" style="Default" icon="right"/></div>
              </div>
              <div className="info">
                <div className="blocks">
                  <div className="blockHead">Date of Birth</div>
                  <div className="blockBody">{formatDate(selectedCase.patient.dob)}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Contact Details</div>
                  <div className="blockBody">{selectedCase.patient.mobilePhoneNumber ? selectedCase.patient.mobilePhoneNumber : selectedCase.patient.phoneNumber}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Discharge Provider</div>
                  <div className="blockBody">{selectedCase.provider.title} {selectedCase.provider.name}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Start Date</div>
                  <div className="blockBody">{formatDateTime(new Date(selectedCase.startDate).toLocaleString())}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">End Date</div>
                  <div className="blockBody">{formatDateTime(new Date(selectedCase.endDate).toLocaleString())}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Appointments</div>
                  <div className="blockBody">
                    {
                    (selectedCase.appointments && selectedCase.appointments.length > 0) ? 
                    selectedCase.appointments.map((appointment, index) => (
                      <div key={index}>
                         <div>{appointment.title} </div>{formatDateTime(new Date(appointment.date).toLocaleString())}
                      </div>
                    ))
                    : 
                    "No appointment data available"
                    }
                  </div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Medications</div>
                  <div className="blockBody">
                    {
                      (selectedCase.medications && selectedCase.medications.length > 0) ? 
                      selectedCase.medications.map((medication, indexx) => (
                          <div key={indexx}>
                              <div>{medication.name} - {medication.dose}</div>
                              <div>{medication.schedule}</div>
                          </div>
                      )) 
                      : 
                      "No medication data available" 
                    }               
                  </div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Case ID</div>
                  <div className="blockBody">{selectedCase.caseId}</div>
                </div>
              </div>
            </div>
            <div className='escalationgrp'>
              <div className="main-head">
                <div className="title">
                  <ErrorIcon /> Escalations
                </div>
              </div>
              {selectedCase.escalations ? (
                selectedCase.escalations.map((escalation, index) => (
                  <div className='esca-mapped' key={index}>
                    <div className="escalations">
                      <div className={`healthcase-head ${expandedEscalationIndex === index ? 'expand' : 'collapse'}`}>
                      <div className="left-esca">
                          <div className="head-esca">
                            <div className="Label-esca">{escalation.taskGraphDescription}</div>
                            <div className="date-esca">
                              <div style={{ width: "Auto" }}>
                                <Button Label={escalation.severity} style={escalation.severity} icon={escalation.severity} />
                              </div>
                              {(!escalation.resolvedAt) ? 
                              <div>
                                Created Date: {formatDateTime(new Date(escalation.createdAt).toLocaleString())}
                              </div> 
                              : 
                              <div>
                              Resolved Date: {formatDateTime(new Date(escalation.resolvedAt).toLocaleString())}
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="right-esca" onClick={() => toggleExpand(index)}>
                          {
                            expandedEscalationIndex === index ? 
                            <div style={{ cursor: "pointer" }}>
                                <KeyboardArrowUpIcon />
                            </div>
                            : 
                            <div style={{ cursor: "pointer" }}>
                                <KeyboardArrowDownIcon />
                            </div>
                          }                          
                        </div>
                      </div>
                      {expandedEscalationIndex === index && (
                              <div className="suggestion">
                                <div className="lbel">{escalation.taskGraphName}</div>
                                <div className="esc-content" style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                  <div>
                                    <b>Type: </b> {escalation.type}
                                  </div>
                                  <div>
                                    <b>{escalation.description}</b>
                                  </div>
                                </div>
                              </div>
                            )
                      }                      
                      {escalation.createdAt}
                      {escalation.resolvedAt}
                    </div>
                  </div>
                ))
              ) : (
                <div className='nullValue'>No escalations available</div>
              )}
            </div>
            <div className={`summary ${isVisible ? 'visible' : 'hidden'}`}>
              <div className="main-head">
                <div className="title">
                  <TtyIcon /> Case Interactions
                </div>
                <div onClick={toggleVisibility} style={{border:"none", margin:"0", padding:"0"}}>
                <div><Button Label="" style="Round" icon="closed"  onClick={toggleVisibility}/></div></div>
              </div>
              {selectedCase.caseInteractions && selectedCase.caseInteractions.length > 0 ? (
                selectedCase.caseInteractions
                .sort((a, b) => new Date(b.attemptDate).getTime() - new Date(a.attemptDate).getTime())
                .map((interaction, index) => (
                  <div className='summaryGRP' key={index}>
                    <div className={`summary-head ${expandedSummaryIndex === index ? 'summary-expand' : 'summary-collapse'}`} onClick={() => toggleExpandSummary(index)}>
                      <div className="left-summary">
                        {expandedSummaryIndex === index ? <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> : <KeyboardArrowDownIcon style={{ cursor: "pointer" }} />}
                      </div>
                      <div className="title-time">
                        <div className='summary-label-name'>Attempt {(selectedCase.caseInteractions.length) - index}</div>
                        <div>
                          {formatDateTime(new Date(interaction.attemptDate).toLocaleString())}
                        </div>
                      </div>
                      <div style={{ width: "Auto" }}>
                        <Button Label={interaction.caseState} style={interaction.caseState} icon={interaction.caseState} />
                      </div>
                    </div>
                    {expandedSummaryIndex === index && (
                      <div className="summary-content-accord">
                        {interaction.taskSummary.map((task, taskIndex) => (
                          <div className='taskGRP' key={taskIndex}>
                            <div className='summary-head'>
                              <div className="title-time">
                                <div className='summary-label-name'>{task.goal}</div>
                              </div>
                              <div key={taskIndex}>
                                {(task.status === 'COMPLETED') ? <CheckCircleOutlineIcon style={{color: '#28a745'}}/> : <HighlightOffIcon style={{color: 'RED'}}/>}
                              </div>
                            </div>
                            {(task.escalation.length > 0) ? 
                            <div className="suggestion" style={{border:"none"}}>
                              <div className="lbel">
                                <div className='esc-content'>
                                  {task.escalation}
                                </div>
                              </div>
                            </div>
                            : 
                              ""
                            }
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
              <div className='nullValue'>No case interactions found.</div>
              )}
            </div>
          </>
        )
        : (
          <CaseNotSelected />
        )} */}
        {selectedCase && (
          <>
            <div className='list-patient-details'>
              <div className="info" style={{color:"#106967", fontSize:"16px"}}>
              <b>{selectedCase.patient.lastName}, {selectedCase.patient.firstName}</b>
              </div>
              <div className="list-documents">
                <div><b>{selectedCase.name}</b></div>
                <div>{formatDateTime(selectedCase.updatedAt)}</div>
              </div>
              <div className="info">
                {(selectedCase.state === "DRAFT")? 
                  <div style={{border:"none", width: "100%", margin:"0", padding:"0"}} onClick={() => handleLaunchCaseClick(selectedCase.caseId)}>
                  <Button Label="Launch Case" style="Ghost" icon="right" />
                </div>
                :
                ""
                }
                
                <div style={{border:"none", width: "100%", margin:"0", padding:"0"}} onClick={() => handleEditCaseClick(selectedCase.caseId)}>
                  <Button Label="Edit Case Details" style="Ghost" icon="right" />
                </div>
                <div onClick={toggleVisibility} style={{border:"none", width: "100%", margin:"0", padding:"0"}}>
                <Button Label="Case Interactions" style="Default" icon="right"/></div>
              </div>
              <div className="info">
                <div className="blocks">
                  <div className="blockHead">Date of Birth</div>
                  <div className="blockBody">{formatDate(selectedCase.patient.dob)}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Contact Details</div>
                  <div className="blockBody">{selectedCase.patient.mobilePhoneNumber ? selectedCase.patient.mobilePhoneNumber : selectedCase.patient.phoneNumber}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Discharge Provider</div>
                  <div className="blockBody">{selectedCase.provider.title} {selectedCase.provider.name}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Start Date</div>
                  <div className="blockBody">{formatDateTime(new Date(selectedCase.startDate).toLocaleString())}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">End Date</div>
                  <div className="blockBody">{formatDateTime(new Date(selectedCase.endDate).toLocaleString())}</div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Appointments</div>
                  <div className="blockBody">
                    {
                    (selectedCase.appointments && selectedCase.appointments.length > 0) ? 
                    selectedCase.appointments.map((appointment, index) => (
                      <div key={index}>
                         <div>{appointment.title} </div>{formatDateTime(new Date(appointment.date).toLocaleString())}
                      </div>
                    ))
                    : 
                    "No appointment data available"
                    }
                  </div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Medications</div>
                  <div className="blockBody">
                    {
                      (selectedCase.medications && selectedCase.medications.length > 0) ? 
                      selectedCase.medications.map((medication, indexx) => (
                          <div key={indexx}>
                              <div>{medication.name} - {medication.dose}</div>
                              <div>{medication.schedule}</div>
                          </div>
                      )) 
                      : 
                      "No medication data available" 
                    }               
                  </div>
                </div>
                <div className="blocks">
                  <div className="blockHead">Case ID</div>
                  <div className="blockBody">{selectedCase.caseId}</div>
                </div>
              </div>
            </div>
            <div className='escalationgrp'>
              <div className="main-head">
                <div className="title">
                  <ErrorIcon /> Escalations
                </div>
              </div>
              {selectedCase.escalations ? (
                selectedCase.escalations.map((escalation, index) => (
                  <div className='esca-mapped' key={index}>
                    <div className="escalations">
                      <div className={`healthcase-head ${expandedEscalationIndex === index ? 'expand' : 'collapse'}`}>
                      <div className="left-esca">
                          <div className="head-esca">
                            <div className="Label-esca">{escalation.taskGraphDescription}</div>
                            <div className="date-esca">
                              <div style={{ width: "Auto" }}>
                                <Button Label={escalation.severity} style={escalation.severity} icon={escalation.severity} />
                              </div>
                              {(!escalation.resolvedAt) ? 
                              <div>
                                Created Date: {formatDateTime(new Date(escalation.createdAt).toLocaleString())}
                              </div> 
                              : 
                              <div>
                              Resolved Date: {formatDateTime(new Date(escalation.resolvedAt).toLocaleString())}
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="right-esca" onClick={() => toggleExpand(index)}>
                          {
                            expandedEscalationIndex === index ? 
                            <div style={{ cursor: "pointer" }}>
                                <KeyboardArrowUpIcon />
                            </div>
                            : 
                            <div style={{ cursor: "pointer" }}>
                                <KeyboardArrowDownIcon />
                            </div>
                          }                          
                        </div>
                      </div>
                      {expandedEscalationIndex === index && (
                              <div className="suggestion">
                                <div className="lbel">{escalation.taskGraphName}</div>
                                <div className="esc-content" style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                  <div>
                                    <b>Type: </b> {escalation.type}
                                  </div>
                                  <div>
                                    <b>{escalation.description}</b>
                                  </div>
                                </div>
                              </div>
                            )
                      }                      
                      {escalation.createdAt}
                      {escalation.resolvedAt}
                    </div>
                  </div>
                ))
              ) : (
                <div className='nullValue'>No escalations available</div>
              )}
            </div>
            <div className={`summary ${isVisible ? 'visible' : 'hidden'}`}>
              <div className="main-head">
                <div className="title">
                  <TtyIcon /> Case Interactions
                </div>
                <div onClick={toggleVisibility} style={{border:"none", margin:"0", padding:"0"}}>
                <div><Button Label="" style="Round" icon="closed"  onClick={toggleVisibility}/></div></div>
              </div>
              {selectedCase.caseInteractions && selectedCase.caseInteractions.length > 0 ? (
                selectedCase.caseInteractions
                .sort((a, b) => new Date(b.attemptDate).getTime() - new Date(a.attemptDate).getTime())
                .map((interaction, index) => (
                  <div className='summaryGRP' key={index}>
                    <div className={`summary-head ${expandedSummaryIndex === index ? 'summary-expand' : 'summary-collapse'}`} onClick={() => toggleExpandSummary(index)}>
                      <div className="left-summary">
                        {expandedSummaryIndex === index ? <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> : <KeyboardArrowDownIcon style={{ cursor: "pointer" }} />}
                      </div>
                      <div className="title-time">
                        <div className='summary-label-name'>Attempt {(selectedCase.caseInteractions.length) - index}</div>
                        <div>
                          {formatDateTime(new Date(interaction.attemptDate).toLocaleString())}
                        </div>
                      </div>
                      <div style={{ width: "Auto" }}>
                        <Button Label={interaction.caseState} style={interaction.caseState} icon={interaction.caseState} />
                      </div>
                    </div>
                    {expandedSummaryIndex === index && (
                      <div className="summary-content-accord">
                        {interaction.taskSummary.map((task, taskIndex) => (
                          <div className='taskGRP' key={taskIndex}>
                            <div className='summary-head'>
                              <div className="title-time">
                                <div className='summary-label-name'>{task.goal}</div>
                              </div>
                              <div key={taskIndex}>
                                {(task.status === 'COMPLETED') ? <CheckCircleOutlineIcon style={{color: '#28a745'}}/> : <HighlightOffIcon style={{color: 'RED'}}/>}
                              </div>
                            </div>
                            {(task.escalation.length > 0) ? 
                            <div className="suggestion" style={{border:"none"}}>
                              <div className="lbel">
                                <div className='esc-content'>
                                  {task.escalation}
                                </div>
                              </div>
                            </div>
                            : 
                              ""
                            }
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
              <div className='nullValue'>No case interactions found.</div>
              )}
            </div>
          </>
        )}
      </div>
      {isOkInfoOpen && (
                    <OkInfo 
                        InfoHead="Case Launched."
                        InfoMessage={successMessage || errorMessage}
                        onClose={closeokInfo} 
                        onSubmit={handleOkInfo} 
                    />
            )}
    </div>
  );
};

export default CaseList;
