import React, { ReactNode } from 'react';
import '../CSS/InputField.css';

type InputFieldProps = {
    LabelName: string;
    PlaceHolder: string;
    Type: string;
    Required: boolean;
    Helper: boolean;
    Error: boolean;
    Disable: boolean;
    HelperText?: string;
    Icon?: ReactNode;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string; // Add a value prop to manage the input value
};

export const InputField: React.FC<InputFieldProps> = ({
    LabelName,
    PlaceHolder,
    Type,
    Required,
    Helper,
    Error,
    Disable,
    HelperText,
    Icon,
    onChange,
    value
}) => {
    return (
        <div className={`InputContainer ${Disable ? 'disabled' : ''}`}>
            <div className="labelName">
                <div className="Label">{LabelName}</div>
                {Required && (
                    <div className="Required">
                        <div className="reqText">Required</div>
                        <div className="asterisk">*</div>
                    </div>
                )}
            </div>
            <div className="InputLineContainer">
                <input
                    className={`InputLine ${Error ? 'InputLineErr' : ''}`}
                    type={Type}
                    placeholder={PlaceHolder}
                    disabled={Disable}
                    onChange={onChange}
                    value={value} // Set the value of the input
                />
                {Icon && <div className="inputIcon">{Icon}</div>}
            </div>
            {Helper && (
                <div className={`helper ${Error ? 'error' : ''}`}>
                    {HelperText}
                </div>
            )}
        </div>
    );
};

export default InputField;
