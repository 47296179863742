export interface Welcome {
    cases:      Case[];
}

export interface Case {
    caseId:              string;
    name:                string;
    organizationGroupId: string;
    patient:             Patient;
    provider:            Provider;
    outcomePlanId:         OutcomePlanID;
    startDate:           string;
    endDate:             string;
    appointments:        Appointment[] | null;
    medications:         Medication[] | null;
    state:               State;
    communicationChannel: string;
    escalations:         Escalation[] | null;
    caseInteractions:    CaseInteraction[];
    searchText:          string;
    createdAt:           string;
    updatedAt:           string;
    createdBy:           string;
    updatedBy:           string;
}

export interface Appointment {
    appointmentId: string | null;
    title: string;
    type: string;
    status: string | null;
    date: string;
    providerId: string;
}

export enum CaseID {
    String = "string",
}

export interface CaseInteraction {
    attemptDate:       string;
    taskSummary:       TaskSummary[];
    caseState:         State;
    escalationOnGroup: null | string;
}

export enum State {
    Active = "ACTIVE",
    Completed = "COMPLETED",
    Critical = "CRITICAL",
    Cancelled = "CANCELLED",
    Draft = "DRAFT",
    Failed = "FAILED"
}

export interface TaskSummary {
    taskId:     string;
    goal:       string;
    status:     State;
    escalation: string[];
}

export interface Escalation {
    escalationId:         string;
    taskGraphInstanceId:  CaseID;
    taskGraphName:        CaseID;
    taskGraphDescription: CaseID;
    taskGraphActionType:  string;
    categoryType:         string;
    status:               string;
    type:                 string;
    severity:             string;
    description:          CaseID;
    createdAt:            string;
    resolvedAt:           string;
    appointmentId?:       CaseID;
    medicationId?:        CaseID;
}

export interface Conversation {
    date:    string;
    message: string;
    sender:  string;
    intent:  null | string;
}

export interface Medication {
    medicationId: string | null;
    name: string;
    dose: string;
    medicationClass: string | null;
    schedule: string;
}

export interface OutcomePlanID {
    id:            string;
    versionNumber: number;
}

export interface Patient {
    patientId:            string;
    organizationId:       string;
    firstName:            string;
    lastName:             string;
    mrn:                  string;
    umrn:                 string;
    source:               string;
    dob:                  string;
    gender:               string;
    phoneNumber:          string;
    mobilePhoneNumber:    string;
    pronoun:              string;
    conversationStyle:    string;
    communicationChannel: string;
    postalCode:           string;
    constraintList:       ConstraintList[];
}

export interface ConstraintList {
    type:     string;
    name:     string;
    active:   boolean;
    severity: string;
}

export interface Provider {
    providerId:     string;
    title:          string;
    name:           string;
    specialization: string;
    organization:   Organization;
}

export interface Organization {
    organizationId: string;
    name: string;
    address: string;
    phoneNumber: string;
    preferredHours: string;
    greeting: string;
    schedulingMessage: string;
    organizationGroup: OrganizationGroup;
}

export interface OrganizationGroup {
    organizationGroupId: string;
    name: string;
    address: string;
    phoneNumber: string;
    milaPhoneNumber: string;
}


export interface Timezone {
    id:    string;
    rules: Rules;
}

export interface Rules {
    fixedOffset:     boolean;
    transitions:     Transition[];
    transitionRules: TransitionRule[];
}

export interface TransitionRule {
    month:               string;
    timeDefinition:      string;
    standardOffset:      OffsetAfter;
    offsetBefore:        OffsetAfter;
    offsetAfter:         OffsetAfter;
    dayOfWeek:           string;
    dayOfMonthIndicator: number;
    localTime:           LocalTime;
    midnightEndOfDay:    boolean;
}

export interface LocalTime {
    hour:   number;
    minute: number;
    second: number;
    nano:   number;
}

export interface OffsetAfter {
    totalSeconds: number;
    id:           string;
}

export interface Transition {
    offsetBefore:   OffsetAfter;
    offsetAfter:    OffsetAfter;
    overlap:        boolean;
    duration:       Duration;
    gap:            boolean;
    dateTimeAfter:  Date;
    dateTimeBefore: Date;
    instant:        Date;
}

export interface Duration {
    seconds:  number;
    zero:     boolean;
    nano:     number;
    negative: boolean;
    positive: boolean;
    units:    Unit[];
}

export interface Unit {
    durationEstimated: boolean;
    timeBased:         boolean;
    dateBased:         boolean;
}
