import React from 'react';
import { Case, State  } from './casetypes'; // Import the interfaces
import "../CSS/GridDash.css";
import CaseCard from './CaseCard';
import EmptyCard from "./EmptyCard";

interface Props {
  jsonData?: {
      cases: Case[];
  };
}

const InProgress: React.FC<Props> = ({ jsonData }) => {
  const casesArray: Case[] = jsonData ? jsonData.cases : [];

  return (
    <div className="cases">
      {casesArray.length > 0 ? (
        casesArray.map((caseData) => {
          if (caseData.state !== State.Cancelled) {
            return (
              <p key={caseData.caseId}>
                <CaseCard 
                  caseID={caseData.caseId}
                  firstName={caseData.patient.firstName} 
                  lastName={caseData.patient.lastName}
                  updatedAt={new Date(caseData.updatedAt).toLocaleString()}
                  State = {caseData.state}
                  Gender = {caseData.patient.gender}
                  DoB = {caseData.patient.dob}
                  CarePlan = {caseData.name}
                />
              </p>
            );
          }
          return null;
        })
      ) : (
      <EmptyCard />
      )}
    </div>
  );
};
export default InProgress;
