import React from "react";
import "../CSS/Titlebar.css";
import "../CSS/CaseList.css";
import "../CSS/AddNewCase.css";
import Button from "./Button";
import SingleDropdown from "./SingleDropdown";
import CountriesDrop from "./CountriesDrop";
import { useNavigate } from 'react-router-dom';
import GenderList from '../JSON/Gender.json';
import PronounsList from '../JSON/Pronouns.json';
import CommChannelList from '../JSON/CommChannel.json';
import Countries from '../JSON/Countries.json';
import InputField from "./InputField";
import DateInput from "./DateInput";
import PhoneInput from "./PhoneInput";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "../CSS/Header.css";
import SearchIcon from "@mui/icons-material/Search";
import { ProviderWelcome } from './providertypes';
import { useEffect, useState } from "react";
import { Case, Welcome } from './casetypes';
import { useMsal } from '@azure/msal-react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AppointmentModal  from './AppointmentModal';
import MedicationModal  from './MedicationModal';
import InformationModal  from './InformationModal';
import CloseIcon from '@mui/icons-material/Close';
import apiURL from "../API/requests";
import OkInfo from "./OkInfo";
import DoBhandle from "./DoBhandle";
import { OutcomePlanData }  from './outcomeplantypes'; 
import OutcomeCare from "./OutcomeCare";
import OPnotSelec from "./OPnotSelect";

interface Props {
    organizationGroupId: string | null;
    outcomePlanId: string | null;
    providerId: string | null;
    outcomePlanss: OutcomePlanData[];
}

interface OutcomePlan {
    id: string;
    versionNumber: number;
  }
  
  interface Constraint {
    type: string;
    name: string;
    active: boolean;
    severity: string;
  }
  
  interface Appointment {
    title: string;
    type: string;
    date: string;
    providerId: string;
  }
  
  interface Medication {
    name: string;
    dose: string;
    medicationClass: string;
    schedule: string;
  }
  
  interface Patient {
    firstName: string;
    lastName: string;
    mrn: string;
    umrn: string;
    source: string;
    dob: string;
    gender: string;
    phoneNumber: string;
    mobilePhoneNumber: string;
    pronoun: string;
    conversationStyle: string;
    communicationChannel: string;
    postalCode: string;
    constraintList: Constraint[];
  }
  
  interface CaseData {
    name: string;
    outcomePlanId: OutcomePlan;
    providerId: string;
    patient: Patient;
    // startDate: string;
    // endDate: string;
    state: string;
    communicationChannel: string;
    searchText: string;
    appointments: Appointment[];
    medications: Medication[];
  }

const AddNewCase: React.FC<Props> = ({outcomePlanId, organizationGroupId, outcomePlanss}) => {
    const navigate = useNavigate();
    const [firstname, setFirstname] = useState('');    
    const [lastname, setLastname] = useState('');
    const [startdate, setStartDate] = useState<string>('');
    const [enddate, setEndDate] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [carePlan, setCarePlan] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [pronoun, setPronoun] = useState<string>('Please Select Pronoun');
    const [commChannel, setCommChannel] = useState<string>('Please Select Communication Channel');
    const [country, setCountry] = useState<string>('+1');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [zipcode, setZipcode] = useState('');
    const [casedata, setCaseData] = useState<Welcome | null>(null);
    const [data, setData] = useState<ProviderWelcome[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { instance, accounts } = useMsal();  
    const [searchQuery, setSearchQuery] = useState<string>(""); // State for search query
    const [suggestions, setSuggestions] = useState<ProviderWelcome[]>([]); // State for search suggestions
    const [selectedProvider, setSelectedProvider] = useState<ProviderWelcome | null>(null);
    const [outcomePlans, setOutcomePlans] = useState<OutcomePlanData[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOkInfoOpen, setIsOkInfoOpen] = useState<boolean>(false);
    const [isMedicModalOpen, setMedicIsModalOpen] = useState(false);
    const [isInfoModalOpen, setInfoModalModalOpen] = useState(false);

    const [appointments, setAppointments] = useState<any[]>([]);
    const [medications, setMedications] = useState<any[]>([]); 

    const [response, setResponse] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [orgGroupId, setOrganizationGroupId] = useState<string>('');
    const [orgId, setOrganizationId] = useState<string>('');
    const [providerId, setProviderId] = useState<string>('');
    const [appoTitle, setAppoTitle] = useState<string>('NA');
    const [appoType, setAppoType] = useState<string>('');
    const [appoDate, setAppoDate] = useState<string>('NA');
    const [appoProID, setAppoProID] = useState<string>('NA');

    const [medicPrescribed, setMedicPrescribed] = useState<string>('');
    const [medicDose, setMedicDose] = useState<string>('');
    const [medicFrequency, setmedicFrequency] = useState<string>('');
    const [medicClass, setMedicClass] = useState<string>('');
    const [infoResponse, setInfoResponse] = useState<any[]>([]); 
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [launch, setLaunch] = useState<string>('DRAFT');

    const [planName, setPlanName] = useState('');
    const [planId, setPlanId] = useState('');
    const [versionNumber, setVersionNumber] = useState(0);

    const [errors, setErrors] = useState({
        carePlan: false,
        firstname: false,
        lastname: false,
        phoneNumber: false,
        commChannel: false,
    });

    useEffect(() => {
        validateFields();
    }, [carePlan, firstname, lastname, phoneNumber, commChannel]);

    const validateFields = () => {
        setErrors({
            carePlan: !carePlan,
            firstname: !firstname,
            lastname: !lastname,
            phoneNumber: !phoneNumber,
            commChannel: !commChannel,
        });
    };
 
    const formatDateTime = (dateTimeString: string) => {
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
        return new Date(dateTimeString).toLocaleString(undefined, options);
      };

    const OutPlanID = outcomePlanId || "";

    const caseData: CaseData = {
        name: planName,
        outcomePlanId: {
          id: OutPlanID,
          versionNumber: versionNumber
        },
        providerId: providerId,
        patient: {
            firstName: firstname,
            lastName: lastname,
            mrn: "MRN-1234",
            umrn: "UMRN-12345",
            source: "DASHBOARD",
            dob: date,
            gender: gender,
            phoneNumber: country + phoneNumber,
            mobilePhoneNumber: country + phoneNumber,
            pronoun: pronoun,
            conversationStyle: "formal",
            communicationChannel: commChannel,
            postalCode: zipcode,
          constraintList: [
            {
              type: "Allergies",
              name: "dairy",
              active: false,
              severity: "mild"
            },
            {
              type: "comorbidity",
              name: "High Blood Pressure",
              active: true,
              severity: "mild"
            },
            {
              type: "intolerance",
              name: "Lactose",
              active: true,
              severity: "mild"
            }
          ]
        },
        state: launch,
        communicationChannel: commChannel,
        searchText: planName + ", " + firstname + " " + lastname,
        appointments: [
            {
                title: appoTitle, 
                type: "PCP_FOLLOW_UP", 
                date: appoDate, 
                providerId: appoProID, 
            }
          ],
        medications: []
      };  
    
      const getToken = async (): Promise<string> => {
        
        try {
          const token = localStorage.getItem("token") || "";
          console.log("Token Access", token);
          return token;
        } catch (error) {
          const token = localStorage.getItem("token") || "";
          return token;
        }
    };
    
    const postCaseData = async (data: CaseData) => {
        try {
          const token = await getToken();
          const response = await fetch(process.env.REACT_APP_CASE || '', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
          });
          console.log("RESSSS:", response);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          // On success
          setSuccessMessage('The case has been successfully created.');
          setErrorMessage(null); // Clear previous error
        } catch (error) {
          // On failure
          setErrorMessage('Failed to create the case. Please try again.');
          setSuccessMessage(null); // Clear previous success message
        } finally {
            setIsOkInfoOpen(true); // Open the modal regardless of success or failure
        }
      };
    
    const handlePostCaseData = async () => {
        setLaunch("DRAFT");
        setLoading(true);
        setError(null);
        try {
            const data = await postCaseData({
                ...caseData,
                state: "DRAFT"
              });
              setResponse(data);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        } finally {
          setLoading(false);
        }
      };
      const handlePostCaseLaunchData = async () => {
        setLaunch("ACTIVE");
        setLoading(true);
        setError(null);
        try {
            const data = await postCaseData({
                ...caseData,
                state: "ACTIVE"
                });
              setResponse(data);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        } finally {
          setLoading(false);
        }
      };

      const isFormValid = !Object.values(errors).some((err) => err);
      
    const openModal = () => {
        setAppointments([]);
        setIsModalOpen(true);
    };

    const openInfoModal = () => {
        setInfoModalModalOpen(true);
    };

    const openMedicModal = () => {
        setMedications([]);
        setMedicIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeMedicModal = () => {
        setMedicIsModalOpen(false);
    };

    const closeInfoModal = () => {
        setInfoModalModalOpen(false);
    };

    const closeokInfo = () => {
        setInfoModalModalOpen(false);
    };

    const getAccessToken = async () => {
        if (accounts.length > 0) {
            try {
                const token = localStorage.getItem("token");
                return token;
            } catch (error) {
                const token = localStorage.getItem("token");
                return token;
            }
        }
        throw new Error('No accounts found');
    };

    const getEntraID = async () => {
        if (accounts.length > 0) {
            try {
                const entrID = localStorage.getItem("entrID");
                return entrID;
            } catch (error) {
                const entrID = localStorage.getItem("entrID");
                return entrID;
            }
        }
        throw new Error('No accounts found');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await getAccessToken();
                // const url =  process.env.REACT_APP_FETCH_LAUNCHED || " ";
                const url =  `${apiURL.fetchLaunched}?status=Launched&offset=0&limit=10`;

                

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result: Welcome = await response.json();
                setCaseData(result);
            } catch (error) {
                setError((error as Error).message);
            }
        };

        fetchData();
    }, []);

    const casesArray: Case[] = casedata ? casedata.cases : [];

    // Define the GetProviderID function
    const GetProviderID = (casesArray: Case[]) => {
        return casesArray.map((caseData) => caseData);
    };
    
    // Use the GetProviderID function to get ProviderIdentity
    const ProviderIdentity = GetProviderID(casesArray);

    // _________________________________

    const handleCarePlanSelect = (value: string) => {
        setCarePlan(value);
    };

    const handleGenderSelect = (value: string) => {
        setGender(value);
    };

    const handlePronounSelect = (value: string) => {
        setPronoun(value);
    };

    const handleCountrySelect = (value: string) => {
        setCountry(value);
    };

    const handleCommChannel = (value: string) => {
        setCommChannel(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const entraID = await getEntraID(); // Awaiting the result of getEntraID
    
            const url = `${apiURL.fetchProviders}${organizationGroupId}/providers?offset=0&limit=10`;
            const token = await getAccessToken();
          
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result: ProviderWelcome[] = await response.json();
                setData(result);
            } catch (error) {
                setError((error as Error).message);
            }
        };
    
        fetchData();
    }, []);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query) {
            const filteredSuggestions = data.filter((provider) =>
                provider.name.toLowerCase().includes(query.toLowerCase()) || 
                provider.organization.name.toLowerCase().includes(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion: ProviderWelcome) => {
        setSearchQuery(`${suggestion.name} ${suggestion.organization.name}`); // Set search query based on selected suggestion
        setSuggestions([]); // Clear suggestions
        setSelectedProvider(suggestion); // Set selected provider
        setOrganizationGroupId(suggestion.organization.organizationGroupId);
        setOrganizationId(suggestion.organization.organizationId);
        setProviderId(suggestion.providerId);
        console.log("Provider: ", suggestion);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setSearchQuery(''); // Clear the search query
            setSuggestions([]); // Clear the suggestions
        } else if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        // Attach event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleModalSubmit = (data: any) => {
        console.log("Data from modal:", data );
        setAppointments(prevAppointments => [...prevAppointments, data]);
        const updatedAppointments = [appointments, data];
        setAppoTitle(updatedAppointments[updatedAppointments.length - 1].appoTitle);
        setAppoType(updatedAppointments[updatedAppointments.length - 1].appoType);
        setAppoDate(updatedAppointments[updatedAppointments.length - 1].appoTypeDate);
        setAppoProID(updatedAppointments[updatedAppointments.length - 1].selectedProvider.providerId);
    };

    const handleMedicationSubmit = (data: any) => {
        console.log("Data from modal:", data );
        setMedications(prevMedications => [...prevMedications, data]);
        const updatedMeic = [medications, data];
        setMedicPrescribed(updatedMeic[updatedMeic.length - 1].medicPrescribed);
        setMedicDose(updatedMeic[updatedMeic.length - 1].medicDose);
        setmedicFrequency(updatedMeic[updatedMeic.length - 1].medicFrequency);
        setMedicClass(updatedMeic[updatedMeic.length - 1].medicClass);
    };

    const handleInfoModalSubmit = (data: any) => {
        console.log("Data from modal:", data);
    
        // Update the state with the new data
        setInfoResponse((prevResponse) => {
            const updatedResponse = [...prevResponse, data];
            // Check if the latest entry in the updatedResponse has `infoResponse`
            if (data.infoResponse) {
                navigate('/');
            }
            return updatedResponse;
        });
    };

    const handleOkInfo = () => {
        navigate('/');
    };

    // Function to clear appointments
    const clearAppointments = () => {
        setAppointments([]);
    };
    const clearMedications = () => {
        setMedications([]);
    };

    // console.log("Outcome Plan Care", outcomePlanss);

    useEffect(() => {
        if (carePlan) {
          try {
            const planDetails = JSON.parse(carePlan);
            setPlanName(planDetails.name);
            setPlanId(planDetails.outcomePlanId);
            setVersionNumber(planDetails.versionNumber);
          } catch (error) {
            console.error("Error parsing care plan details:", error);
          }
        }
      }, [carePlan]);


    return (
        <div style={{ display: "block", marginBottom: "12px" }}>
            {/* <div className="titlebar" style={{ marginBottom: "12px" }}> */}
            <div className="titlebar" style={{ marginBottom: "7%" }}>
                <div className="Title">
                    Add New Case
                </div>
                <div className="actions">
                    
                    {/* <div><Button Label="Submit for Review" style="disabled"/></div> */}
                        <div>
                            <button 
                                className={`${loading || !isFormValid ? 'disabled' : 'Alt'}`} 
                                onClick={handlePostCaseLaunchData} 
                                disabled={loading || !isFormValid}
                            >  
                                {loading ? 'Please wait, launching case...' : 'Launch Case'} 
                            </button>
                        </div>
                        <div>
                            <button 
                                className={`${loading || !isFormValid ? 'disabled' : 'Alt'}`} 
                                onClick={handlePostCaseData} 
                                disabled={loading || !isFormValid}
                            >  
                                {loading ? 'Please wait, saving draft...' : 'Save Draft for Review'} 
                            </button>
                        </div>                        
                    <div  onClick={openInfoModal}><Button Label="Cancel" style="Alt-Ghost" /></div>
                </div>
            </div>
            <div className="AddCaseView">
                <div className="newcase-carecard" style={{padding:"12px"}}>
                    <div className="newcase-healthcase-head">
                        Available Care Plans
                    </div>
                    <div className="newcase-care-drop-body">
                        <OutcomeCare 
                            selectedValue={"Please Select Care Plan"} 
                            LabelName={"Name of Care Plan"} 
                            HelperText={"Select a care plan from the list"}
                            Required={true} 
                            Helper={false} 
                            ListItems={outcomePlanss} 
                            getItemValue={(item: OutcomePlanData) => item.name} 
                            onSelect={handleCarePlanSelect}
                        />
                    </div>
                </div>
                {
                (carePlan) ?
                    <div className="details">
                        <div className="newcase-carecard" style={{padding:"24px", display:"flex", flexDirection:"row", gap:"24px"}}>
                            <div className="newcase-container">
                                        <InputField
                                            LabelName="First Name"
                                            PlaceHolder="Enter First Name"
                                            Type="text"
                                            Required={true}
                                            Helper={false}
                                            Error={false}
                                            Disable={false}
                                            HelperText="Use the registered user name"
                                            Icon={""}
                                            onChange={(e) => setFirstname(e.target.value)}
                                            value={firstname}
                                        />
                                        <InputField
                                            LabelName="Last Name"
                                            PlaceHolder="Enter Last Name"
                                            Type="text"
                                            Required={true}
                                            Helper={false}
                                            Error={false}
                                            Disable={false}
                                            HelperText="Use the registered user name"
                                            Icon={""}
                                            onChange={(e) => setLastname(e.target.value)}
                                            value={lastname}
                                        />
                                        <SingleDropdown 
                                            selectedValue={"Please Select Gender"} 
                                            LabelName={"Gender"} 
                                            HelperText={"Some helper"} 
                                            Required={true} 
                                            Helper={false} 
                                            ListItems={GenderList}
                                            getItemValue={(item) => item.Gender} // Ensure the correct property
                                            onSelect={handleGenderSelect} // Pass the handler
                                        />
                                        <SingleDropdown 
                                            selectedValue={"Please Select Pronoun"} 
                                            LabelName={"Pronouns"} 
                                            HelperText={"Some helper"} 
                                            Required={true} 
                                            Helper={false} 
                                            ListItems={PronounsList}
                                            getItemValue={(item) => item.Gender} // Ensure the correct property
                                            onSelect={handlePronounSelect} // Pass the handler
                                        />
                                        <SingleDropdown 
                                            selectedValue={"Please Select Communication Channel"} 
                                            LabelName={"Communication Channel"} 
                                            HelperText={"Some helper"} 
                                            Required={true} 
                                            Helper={false} 
                                            ListItems={CommChannelList}
                                            getItemValue={(item) => item.ListValue} // Ensure the correct property
                                            onSelect={handleCommChannel} // Pass the handler
                                        />
                                        <DoBhandle label={"Date of Birth"} date={date} setDate={setDate} />
                                        <div className="patient-phone">
                                            <div className="labelName">
                                                <div className="Label">Mobile Number</div>
                                                    <div className="Required">
                                                        <div className="reqText">Required</div>
                                                        <div className="asterisk">*</div> 
                                                    </div>
                                                </div>
                                                <div className="code-input">
                                                        <CountriesDrop 
                                                            selectedValue={"+1"} 
                                                            LabelName={"Gender"} 
                                                            HelperText={"Some helper"} 
                                                            Required={true} 
                                                            Helper={false} 
                                                            ListItems={Countries}
                                                            getItemValue={(item) => item.Country} // Ensure the correct property
                                                            onSelect={handleCountrySelect} // Pass the handler
                                                        />
                                                        <PhoneInput
                                                            onChange={setPhoneNumber}
                                                            value={phoneNumber}
                                                        />
                                                </div>
                                        </div>
                                        <InputField
                                        LabelName="Zip Code"
                                        PlaceHolder="Enter Location Zip Code"
                                        Type="number"
                                        Required={true}
                                        Helper={false}
                                        Error={false}
                                        Disable={false}
                                        HelperText="Use the registered user name"
                                        Icon={<LocationOnIcon />}
                                        onChange={(e) => setZipcode(e.target.value)}
                                        value={zipcode}
                                         />                    

                            </div>
                            <div className="newcase-container">                    
                                    <div className="newcase-carecard">
                                        <div className="newcase-healthcase-head">
                                            Discharging Provider
                                        </div>
                                        <div className="newcase-care-drop-body">
                                            <div className="NewCasesearchBar">
                                                <div className="Search SearchSize">
                                                    <SearchIcon />
                                                </div>
                                                <form>
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchQuery}
                                                        onChange={handleSearchInputChange}
                                                    />
                                                    <button type="submit"></button>
                                                </form>
                                                {suggestions.length > 0 && (
                                                    <div className="suggestions">
                                                        {suggestions.map((suggestion, index) => (
                                                            <div
                                                                key={index}
                                                                className="new-case-suggestion-search"
                                                                onClick={() => handleSuggestionClick(suggestion)}
                                                            >
                                                                <b>{suggestion.name}, {suggestion.emailId}</b>, Organisation: {suggestion.organization.name}, {suggestion.specialization}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            {selectedProvider && (
                                                <>

                                                    <div className="newcase-healthcase-head" style={{fontSize:"18px", marginTop:"12px"}}>
                                                        Slected Discharging Provider
                                                    </div>
                                                    <div className="newcase-care-drop-body">
                                                    <div className="Provider-blockBody"> <b>organizationGroupId</b> <ArrowRightIcon /> {orgGroupId} </div>
                                                    <div className="Provider-blockBody"> <b>providerId</b> <ArrowRightIcon /> {selectedProvider.providerId}  </div>
                                                    <div className="Provider-blockBody"> <b>NAME</b> <ArrowRightIcon /> {selectedProvider.name}  </div>
                                                    <div className="Provider-blockBody"> <b>Email</b> <ArrowRightIcon /> {selectedProvider.emailId}  </div>
                                                    <div className="Provider-blockBody"> <b>Contact Number</b> <ArrowRightIcon /> {selectedProvider.organization.phoneNumber}  </div>
                                                    <div className="Provider-blockBody"> <b>Organization</b> <ArrowRightIcon /> {selectedProvider.organization.name}  </div>
                                                    <div className="Provider-blockBody"> <b>Specialization</b> <ArrowRightIcon /> {selectedProvider.specialization}  </div>
                                                    </div>
                                                </>
                                            )}                            
                                        </div>
                                    </div>
                                    {/* <DateInput label={"Start Date"} date={startdate} setDate={setStartDate} />
                                    <DateInput label={"End Date"} date={enddate} setDate={setEndDate} /> */}
                                    <div className="newcase-carecard">
                                        <div className="newcase-healthcase-head">
                                            <div>Appointments</div>
                                            <button style={{ border: "none", padding: "0", borderRadius:"8px" }} onClick={openModal}><Button Label="Add Appointment" style="Alt-Ghost" /></button>
                                        </div>
                                        <div className="newcase-care-drop-body" style={{width:"100%"}}>
                                            {appointments.map((appointment, index) => (
                                                <div key={index} style={{width:"100%"}}>
                                                    <>
                                                    <div className="newcase-healthcase-head" style={{fontSize:"18px", marginTop:"12px"}}>
                                                        Appointment Details
                                                        <div className="subClose" onClick={clearAppointments}><CloseIcon /></div>
                                                    </div>
                                                    <div className="newcase-care-drop-body">
                                                        <div className="Provider-blockBody"> <b>Title</b> <ArrowRightIcon /> {appointment.appoTitle}  </div>
                                                        <div className="Provider-blockBody"> <b>Type</b> <ArrowRightIcon /> {appointment.appoType}  </div>
                                                        <div className="Provider-blockBody"> <b>Date</b> <ArrowRightIcon /> {formatDateTime(appointment.appoTypeDate)}  </div>
                                                    </div>
                                                    {appointment.selectedProvider && (
                                                    <div className="newcase-care-drop-body">
                                                        <div className="Provider-blockBody"> <b>Provider Name</b> <ArrowRightIcon /> {appointment.selectedProvider.name }  </div>
                                                        <div className="Provider-blockBody"> <b>Provider Organization</b> <ArrowRightIcon /> {appointment.selectedProvider.organization.name}  </div>
                                                        <div className="Provider-blockBody"> <b>Provider ID</b> <ArrowRightIcon /> {appointment.selectedProvider.providerId}  </div>
                                                    </div>                                        
                                                    )}
                                                    </>
                                                </div>

                                            ))}                            
                                        </div>
                                    </div>
                                    <div className="newcase-carecard">
                                        <div className="newcase-healthcase-head">
                                            <div>Medications</div>
                                            <button style={{ border: "none", padding: "0", borderRadius:"8px" }} onClick={openMedicModal}><Button Label="Add Medication" style="Alt-Ghost" /></button>
                                        </div>
                                        <div className="newcase-care-drop-body">
                                            {medications.map((medication, index) => (
                                                <div key={index} style={{width:"100%"}}>
                                                    <>
                                                    <div className="newcase-healthcase-head" style={{fontSize:"18px", marginTop:"12px"}}>
                                                        Medication Details
                                                        <div className="subClose" onClick={clearMedications}><CloseIcon /></div>
                                                    </div>
                                                    <div className="newcase-care-drop-body">
                                                        <div className="Provider-blockBody"> <b>Title</b> <ArrowRightIcon /> {medication.medicPrescribed }  </div>
                                                        <div className="Provider-blockBody"> <b>Dose</b> <ArrowRightIcon /> {medication.medicDose}  </div>
                                                        <div className="Provider-blockBody"> <b>Frequency</b> <ArrowRightIcon /> {medication.medicFrequency}  </div>
                                                        <div className="Provider-blockBody"> <b>Class</b> <ArrowRightIcon /> {medication.medicClass}  </div>
                                                    </div>
                                                    </>                                    
                                                </div>
                                            ))}                             
                                        </div>
                                    </div>
                                    {/* Part 2
                                    <div className="selected-date">
                                        Selected Date: {date}
                                        <div>organizationGroupId: {orgGroupId}</div>
                                        <div>organizationId: {orgId}</div>
                                        <div>Outcome Plan ID: {OutPlanID}</div>
                                        <div>Provider ID: {providerId}</div>
                                        <div>First Name: {firstname}</div>
                                        <div>Last Name: {lastname}</div>
                                        <div>Care Plan: {carePlan}</div>
                                        <div>
                                            <p>Name: {planName}</p>
                                            <p>ID: {planId}</p>
                                            <p>Version: {versionNumber}</p>
                                        </div>                                        
                                        <div>Gender: {gender}</div>   
                                        <div>Pronoun: {pronoun}</div>   
                                        <div>CommChannel: {commChannel}</div>   
                                        <div>Phone: {country + phoneNumber}</div>    
                                        <div>Zipcode: {zipcode}</div>       
                                        <div>Start Date: {startdate}</div>  
                                        <div>End Date: {enddate}</div>        
                                        <div>appoTitle: {appoTitle}</div>   
                                        <div>appoType: {appoType}</div>  
                                        <div>appoType: {appoDate}</div> 
                                        <div>appoType: {appoProID}</div>             
                                    </div>                     */}
                            </div>                            
                        </div>
                    </div>
                : 
                <div className="ListView">
                    <OPnotSelec />
                </div>
                
                }
            </div>
            {/* Conditionally render the modal */}
            {isModalOpen && <AppointmentModal organizationGroupId={organizationGroupId} onClose={closeModal} onSubmit={handleModalSubmit} />}
            {isMedicModalOpen && <MedicationModal onClose={closeMedicModal} onSubmit={handleMedicationSubmit} />}
            {isInfoModalOpen && 
                <InformationModal 
                    InfoHead="Are you sure you want to cancel the form?"
                    InfoMessage="Upon confirmation, any data entered in the form will be lost."
                    onClose={closeInfoModal} 
                    onSubmit={handleInfoModalSubmit} 
                />
            }
            {isOkInfoOpen && (
                    <OkInfo 
                        InfoHead="Case Creation Information."
                        InfoMessage={successMessage || errorMessage}
                        onClose={closeokInfo} 
                        onSubmit={handleOkInfo} 
                    />
            )}
        </div>
    );
}

export default AddNewCase;
