// Button.tsx
import React from 'react';
import "../CSS/Button.css";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
interface ButtonProps {
    Label: string;
    style: string;
    onClick?: () => void; // Make onClick optional
}

const Button: React.FC<ButtonProps> = ({ Label, style, onClick }) => {
    return (
        <button className={`btn ${style}`} onClick={onClick}>
            {Label}<ChevronRightTwoToneIcon className="transp"/>
        </button>
    );
};

export default Button;
