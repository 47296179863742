import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, UserState } from './UserState';

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state: UserState, action: PayloadAction<UserState>) => {
            state = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;