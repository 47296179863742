import React from "react";
import "../CSS/Titlebar.css";
import "../CSS/CaseList.css";
import "../CSS/AddNewCase.css";
import Button from "./Button";
import "../CSS/Header.css";
import { useState } from "react";
import LightbulbIcon from '@mui/icons-material/Lightbulb';

interface InfoModalProps {
    InfoHead: string;
    InfoMessage: string | null;
    onClose: () => void;
    onSubmit: (data: any) => void;
  }
  
  const OkInfo: React.FC<InfoModalProps> = ({ InfoHead, InfoMessage, onClose, onSubmit }) => {
    const [infoResponse, setInfoResponse] = useState<boolean>(true);

    const handleInfoFeedback = () => {
        setInfoResponse(true);
        const InfoData = {
            infoResponse
        };
        onSubmit(InfoData);
        onClose();
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="main-head">
                    <div className="title">
                        <LightbulbIcon />Information
                    </div>
                    {/* <div onClick={onClose}>
                        <Button Label="" style="Round" icon="closed" />
                    </div> */}
                </div>
                <div className="modal-container" style={{height:"auto", gap:"16px", marginBottom:"24px"}}>
                    <div className="info-head">
                        {InfoHead}
                    </div>
                    <div className="info-content">
                        {InfoMessage}
                    </div>     
                </div>
                <div className="actions" style={{marginTop: "12px"}}>
                    <div  style={{width:"100%"}}onClick={handleInfoFeedback} ><Button Label="View Dashoard" style="Default"/></div>
                </div>
            </div>
        </div>
    );
}
export default OkInfo;