import React, { useState, useEffect } from "react";
import "../CSS/Titlebar.css";
import "../CSS/CaseList.css";
import "../CSS/AddNewCase.css";
import Button from "./Button";
import SingleDropdown from "./SingleDropdown";
import { useMsal } from '@azure/msal-react';
import InputField from "./InputField";
import DateInput from "./DateInput";
import TimeInput from "./TimeInput";
import SearchIcon from "@mui/icons-material/Search";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import apiURL from "../API/requests";
import { ProviderWelcome } from './providertypes';
import { Case } from './casetypes';
import AppointmentType from '../JSON/AppointmentType.json';

const Modal: React.FC<{ organizationGroupId: string | null; onClose: () => void; onSubmit: (data: any) => void }> = ({ onClose, onSubmit, organizationGroupId }) => {
    const { instance, accounts } = useMsal();
    const [data, setData] = useState<ProviderWelcome[]>([]);
    const [orgid, setOrgID] = useState<Case[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [appoTitle, setAppoTitle] = useState('');
    const [appoType, setAppoType] = useState<string>('Select the Type');
    const [appoTypeDate, setAppoTypeDate] = useState<string>('');
    const [appoTypeTime, setAppoTypeTime] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<ProviderWelcome[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<ProviderWelcome | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);

    const handleAppoTypeSelect = (value: string) => {
        setAppoType(value);
    };
    console.log(accounts);
    const getAccessToken = async () => {
        if (accounts.length > 0) {
            try {
                const token = localStorage.getItem("token");
                return token;
            } catch (error) {
                const token = localStorage.getItem("token");
                return token;
            }
        }
        throw new Error('No accounts found');
    };

    const getEntraID = async () => {
        if (accounts.length > 0) {
            try {
                const entrID = localStorage.getItem("entrID");
                return entrID;
            } catch (error) {
                const entrID = localStorage.getItem("entrID");
                return entrID;
            }
        }
        throw new Error('No accounts found');
    };

    useEffect(() => {
        const fetchData = async () => {
            const entraID = await getEntraID(); // Awaiting the result of getEntraID
            // console.log(entraID);
    
            const url = `${apiURL.fetchProviders}${organizationGroupId}/providers?offset=0&limit=10`;
            const token = await getAccessToken();
          
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result: ProviderWelcome[] = await response.json();
                setData(result);
            } catch (error) {
                setError((error as Error).message);
            }
        };
    
        fetchData();
    }, []);
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query) {
            const filteredSuggestions = data.filter((provider) =>
                provider.name.toLowerCase().includes(query.toLowerCase()) || 
                provider.organization.name.toLowerCase().includes(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion: ProviderWelcome) => {
        setSearchQuery(`${suggestion.name} ${suggestion.organization.name}`); // Set search query based on selected suggestion
        setSuggestions([]); // Clear suggestions
        setSelectedProvider(suggestion); // Set selected provider
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setSearchQuery(''); // Clear the search query
            setSuggestions([]); // Clear the suggestions
        } else if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        // Attach event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleCreateAppointment = () => {
        // Check if required fields are filled
        if (!appoTitle || appoType === 'Select the Type' || !appoTypeDate || !appoTypeTime || !selectedProvider) {
            setValidationError('All the fields are required.');
            return;
        }

        const appointmentData = {
            appoTitle,
            appoType,
            appoTypeDate: changeTime(appoTypeDate, appoTypeTime),
            selectedProvider
        };
        onSubmit(appointmentData);
        onClose();
    };

    function changeTime(dateString: string, newTime: string): string {
        // Parse the date string to a Date object
        const date = new Date(dateString);
      
        // Extract hours and minutes from the new time string
        const [hours, minutes] = newTime.split(':').map(Number);
      
        // Set the hours and minutes of the Date object
        date.setUTCHours(hours);
        date.setUTCMinutes(minutes);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
      
        // Convert the modified Date object back to an ISO string
        return date.toISOString();
      } 

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="main-head">
                    <div className="title">
                        <LightbulbIcon />Appointments
                    </div>
                    <div onClick={onClose}>
                        <Button Label="" style="Round" icon="closed" />
                    </div>
                </div>
                <div className="modal-container">
                    {validationError && (
                        <div className="helperErr">{validationError}</div>
                    )}
                    <InputField
                        LabelName="Appointment Title"
                        PlaceHolder="Enter a title for this appointment"
                        Type="text"
                        Required={true}
                        Helper={false}
                        Error={false}
                        Disable={false}
                        HelperText="Use the registered user name"
                        Icon={""}
                        onChange={(e) => setAppoTitle(e.target.value)}
                        value={appoTitle}
                    />
                    <div className="newcase-carecard">
                        <div className="newcase-healthcase-head" style={{fontSize:"18px"}}>
                            Appointment Type
                        </div>
                        <div className="newcase-care-drop-body">
                            <SingleDropdown 
                                selectedValue={"Select the Type"} 
                                LabelName={""} 
                                HelperText={"Some helper"} 
                                Required={false} 
                                Helper={false} 
                                ListItems={AppointmentType}
                                getItemValue={(item) => item.ListValue} // Ensure the correct property
                                onSelect={handleAppoTypeSelect} // Pass the handler
                            />
                        </div>
                    </div>

                    <DateInput label={"Appointment Date"} date={appoTypeDate} setDate={setAppoTypeDate} />                 
                    <TimeInput label={"Appointment Time"} time={appoTypeTime} setTime={setAppoTypeTime} />  
                    <div className="newcase-carecard">
                        <div className="newcase-healthcase-head" style={{fontSize:"18px"}}>
                            Provider
                        </div>  
                        <div className="newcase-care-drop-body">
                            <div className="NewCasesearchBar">
                                <div className="Search SearchSize">
                                    <SearchIcon />
                                </div>
                                <form>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                    />
                                    <button type="submit"></button>
                                </form>
                                {suggestions.length > 0 && (
                                    <div className="suggestions">
                                        {suggestions.map((suggestion, index) => (
                                            <div
                                                key={index}
                                                className="new-case-suggestion-search"
                                                onClick={() => handleSuggestionClick(suggestion)}
                                            >
                                                <div>
                                                    <b>{suggestion.organization.name}</b>, Organisation: {suggestion.name}, {suggestion.specialization}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>   
                    </div>                             

                    {selectedProvider && (
                        <>
                            <div className="newcase-healthcase-head" style={{fontSize:"18px", marginTop:"12px"}}>
                                Selected Provider
                            </div>
                            <div className="newcase-care-drop-body">
                                <div className="Provider-blockBody"> <b>NAME</b> <ArrowRightIcon /> {selectedProvider.name}  </div>
                                <div className="Provider-blockBody"> <b>Email</b> <ArrowRightIcon /> {selectedProvider.emailId}  </div>
                                <div className="Provider-blockBody"> <b>Contact Number</b> <ArrowRightIcon /> {selectedProvider.organization.phoneNumber}  </div>
                                <div className="Provider-blockBody"> <b>Organization</b> <ArrowRightIcon /> {selectedProvider.organization.name}  </div>
                                <div className="Provider-blockBody"> <b>Specialization</b> <ArrowRightIcon /> {selectedProvider.specialization}  </div>
                                <div className="Provider-blockBody"> <b>Provider ID</b> <ArrowRightIcon /> {selectedProvider.providerId}  </div>
                            </div>
                        </>
                    )}           
                </div>
                <div className="actions" style={{marginTop: "12px"}}>
                    <div  style={{width:"100%"}} onClick={handleCreateAppointment}><Button Label="Create Appointment" style="Default" /></div>
                    <div style={{width:"40%"}} onClick={onClose}><Button Label="Cancel" style="Ghost" /></div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
