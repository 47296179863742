import React from 'react';
import '../CSS/DateInput.css';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import "react-datepicker/dist/react-datepicker.css";

interface TimeInputProps {
  label: string;
  time: string;
  setTime: (time: string) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ label, time, setTime }) => {
  const dateInputRef = React.useRef<HTMLInputElement>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const handleIconClick = () => {
    dateInputRef.current?.showPicker();
  };

  return (
    <div className="date-input-container">
      <label htmlFor="time" className="date-label">{label}</label>
      <div className="date-input-wrapper">
        <input
          type="time"
          id="time"
          name="time"
          value={time}
          onChange={handleDateChange}
          className="date-input"
          ref={dateInputRef}
        />
        <span className="calendar-icon" onClick={handleIconClick}>
          <AccessAlarmIcon />
        </span>
      </div>
    </div>
  );
};

export default TimeInput;
