import Button from "./Button";
import "../CSS/Card.css";
import { useNavigate } from 'react-router-dom';

type CaseCardProps = {
  caseID: string;
  firstName: string;
  lastName: string;
  Gender: string;
  DoB: string;
  updatedAt: string;
  State: string;
  CarePlan: string;
};

const calculateAge = (dateOfBirthString: string): number => {
  const today = new Date();
  const birthDate = new Date(dateOfBirthString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const CaseCard = (props: CaseCardProps) => {
  const navigate = useNavigate();
  const CaseSeverity = "Critical";
  const age = calculateAge(props.DoB);
  const formatDateTime = (dateTimeString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  const handleDetailsClick = () => {
    navigate(`/case-details/${props.caseID}`);
  };

  return (
    <div className="patient-card">
      <div className="patient-name">
        <div className="ActualName">
        {props.firstName} {props.lastName}
        </div>
        <div className="Severity">
              <div><Button Label={props.State} style={props.State} icon={props.State} /></div>
          <div className="GenderAge">
          {props.Gender}, {age} years
          </div>
        </div>
      </div>

      <div className="documents">
        <div className="line1">{props.CarePlan}</div>
        <div className="line2">Updated on: {formatDateTime(props.updatedAt)}</div>
      </div>

      <div className="button-section">
        <div style={{ width: "100%", display: "inline-block" }}>
          <div style={{border:"none", padding:"0", width:"100%"}} onClick={handleDetailsClick}><Button Label="Details" style="Default" icon="right" /></div>
        </div>

        <div style={{ width: "auto", display: "inline-block" }}>
          <div style={{border:"none", padding:"0", width:"100%"}}><Button Label="Edit" style="Ghost" icon="edit" /></div>
        </div>
      </div>
    </div>
  );
}

export default CaseCard;
