import "../CSS/Header.css";
import logo from "../Images/Mila-Health-Logo.png";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PofilePic from "../Images/ProfileIcon.png";
import ProfileDropdownMenu from "../Components/ProfileDropdownMenu";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Case } from './casetypes';

interface Props {
    UserName: string;
    Designation: string;
    cases: Case[];
  }
  
export const Header: React.FC<Props> = ({ UserName, Designation, cases }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>(""); // State for search query
    const [suggestions, setSuggestions] = useState<Case[]>([]); // State for search suggestions
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handlDropDownFocus = (state: boolean) => {
        setOpen(!state);
    };

    const handleClickOutsideDropdown = (e: any) => {
        if (open && !dropdownRef.current?.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    window.addEventListener("click", handleClickOutsideDropdown);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query) {
            const filteredSuggestions = caseData.filter((caseItem) =>
                caseItem.patient.firstName.toLowerCase().includes(query.toLowerCase()) || 
                caseItem.patient.lastName.toLowerCase().includes(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleSuggestionClick = (suggestion: Case) => {
        setSearchQuery(`${suggestion.patient.firstName} ${suggestion.patient.lastName}`); // Set search query based on selected suggestion
        setSuggestions([]); // Clear suggestions
        navigate(`/DashCaseDetails/${suggestion.caseId}`); // Navigate to DashCaseDetails with caseID
    };
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setSearchQuery(''); // Clear the search query
            setSuggestions([]); // Clear the suggestions
        }
    };

    useEffect(() => {
        // Attach event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const caseData: Case[] = cases ? cases: [];

    return (
        <div className="header">
            <div className="leftelements">
                <div className="sidebaricon">
                    <MenuIcon />
                </div>
                <img className="logo" src={logo} alt="Logo" />
                <div className="searchBar">
                    <div className="Search SearchSize">
                        <SearchIcon />
                    </div>
                    <form>
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                        />
                        <button type="submit"></button>
                    </form>
                    {suggestions.length > 0 && (
                        <div className="suggestions">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="suggestion-search"
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    <b>{suggestion.patient.firstName}, {suggestion.patient.lastName}</b>, Provider: {suggestion.provider.name}, {suggestion.provider.organization.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="rightelements">
                <div className="help iconSize">
                    <HelpOutlineOutlinedIcon />
                </div>
                <div className="settings iconSize">
                    <SettingsOutlinedIcon />
                </div>
                {/* Dropdown Menu */}
                <div className="containers" ref={dropdownRef}>
                    <img
                        className="ProfilePicIcon"
                        alt=""
                        src={PofilePic}
                        onClick={(e) => handlDropDownFocus(open)}
                    />
                    <div>
                        {open && (
                            <ProfileDropdownMenu
                                UserName={UserName}
                                Designation={Designation}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
