import React, { useEffect, useState } from "react";
import "../CSS/Titlebar.css";
import "../CSS/CaseList.css";
import "../CSS/AddNewCase.css";
import "../CSS/Header.css";
import Button from "./Button";
import InputField from "./InputField";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MedicationClass from '../JSON/MedicationClass.json';
import SingleDropdown from "./SingleDropdown";

const Modal: React.FC<{ onClose: () => void; onSubmit: (data: any) => void }> = ({ onClose, onSubmit }) => {
    const [medicPrescribed, setmedicPrescribed] = useState('');
    const [medicDose, setMedicDose] = useState('');
    const [medicFrequency, setMedicFrequency] = useState('');
    const [medicClass, setMedicClass] = useState<string>('Please Select Medication Class');
    const [validationError, setValidationError] = useState<string | null>(null);

    const handleCreateAppointment = () => {
        // Check if required fields are filled
        if (!medicPrescribed || !medicDose || !medicFrequency || medicClass === 'Please Select Medication Class') {
            setValidationError('Please fill in all required fields.');
            return;
        }

        const MedicationData = {
            medicPrescribed,
            medicDose,
            medicFrequency,
            medicClass
        };
        onSubmit(MedicationData);
        onClose();
    };

    const handleMedicClassSelect = (value: string) => {
        setMedicClass(value);
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="main-head">
                    <div className="title">
                        <LightbulbIcon />Medication
                    </div>
                    <div onClick={onClose}>
                        <Button Label="" style="Round" icon="closed" />
                    </div>
                </div>
                <div className="modal-container" style={{ gap: "16px" }}>
                    {validationError && (
                        <div className="helperErr">{validationError}</div>
                    )}
                    <InputField
                        LabelName="Medicine Prescribed Name"
                        PlaceHolder="Drug / Medicine Name"
                        Type="text"
                        Required={true}
                        Helper={false}
                        Error={false}
                        Disable={false}
                        HelperText="Fill as indicated from the physician"
                        Icon={""}
                        onChange={(e) => setmedicPrescribed(e.target.value)}
                        value={medicPrescribed}
                    />
                    <InputField
                        LabelName="Recommended Dosage"
                        PlaceHolder="100 mg"
                        Type="text"
                        Required={true}
                        Helper={false}
                        Error={false}
                        Disable={false}
                        HelperText="Fill as indicated from the physician"
                        Icon={""}
                        onChange={(e) => setMedicDose(e.target.value)}
                        value={medicDose}
                    />
                    <InputField
                        LabelName="Recommended Dosage Frequency"
                        PlaceHolder="Example: Morning 2 capsules, afternoon 1"
                        Type="text"
                        Required={true}
                        Helper={false}
                        Error={false}
                        Disable={false}
                        HelperText="Fill as indicated from the physician"
                        Icon={""}
                        onChange={(e) => setMedicFrequency(e.target.value)}
                        value={medicFrequency}
                    />
                    <SingleDropdown
                        selectedValue={"Please Select Medication Class"}
                        LabelName={"Medication Class"}
                        HelperText={"Some helper"}
                        Required={true}
                        Helper={false}
                        ListItems={MedicationClass}
                        getItemValue={(item) => item.ListItem} // Ensure the correct property
                        onSelect={handleMedicClassSelect} // Pass the handler
                    />
                </div>
                <div className="actions" style={{ marginTop: "12px" }}>
                    <div style={{ width: "100%" }} onClick={handleCreateAppointment}>
                        <Button Label="Add Medication" style="Default" />
                    </div>
                    <div style={{ width: "40%" }} onClick={onClose}>
                        <Button Label="Cancel" style="Ghost" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
