import PersonNote from "../Images/Person Note.png";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "../CSS/EmptyCard.css";

export const EmptyCard = () => {
  return (

      <div className="EmptyCard">
          <div className="Helpbar">
            <div className="help iconSizeInContent">
              <HelpOutlineOutlinedIcon />
            </div>
          </div>
          <img className="PersonNote" src={PersonNote} alt="Logo" />
          <div className="Heading2">
            You do not have any cases published in the dashboard.
          </div>
          <div className="helper">
            Once a case is published upon successful review it will appear here
            along with its current status.
          </div>
        </div>

    
  );
}
export default EmptyCard;
