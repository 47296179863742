import "../CSS/ListDash.css";
import "../CSS/Titlebar.css";
import React, { useState } from 'react';
import { Case, State } from './casetypes';
import CaseList from "./CasesList";
import LoadingCard from "./LoadingCard";
import Button from "./Button";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';

interface Props {
  user: string;
  cases: Case[];
  draft: Case[];
}

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Poppins',
    },
  },
});

const ListDash: React.FC<Props> = ({ user, cases, draft }) => {
  const navigate = useNavigate();
  const [sortByDateAsc, setSortByDateAsc] = useState(true); // State for sorting direction
  const [sortBySeverity, setSortBySeverity] = useState(false); // State for sorting by severity
  const [showDraft, setShowDraft] = useState(false); // State for showing draft cases

  const getStatePriority = (state: State) => {
    switch (state) {
      case State.Critical:
        return 1;
      case State.Active:
        return 2;
      case State.Completed:
        return 3;
      case 'CANCELLED':
        return 4;
      default:
        return 5;
    }
  };
  
  // Function to sort cases by date
  const sortCasesByDate = (cases: Case[]) => {
    return cases.slice().sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return sortByDateAsc ? dateA - dateB : dateB - dateA; // Toggle sorting direction
    });
  };

  // Function to sort cases by severity
  const sortCasesBySeverity = (cases: Case[]) => {
    return cases.slice().sort((a, b) => {
      const stateA = getStatePriority(a.state);
      const stateB = getStatePriority(b.state);
      return stateA - stateB;
    });
  };

  // Handler for sorting toggle change by date
  const handleSortToggleChange = () => {
    setSortByDateAsc(!sortByDateAsc);
  };

  // Handler for sorting toggle change by severity
  const handleSortBySeverityChange = () => {
    setSortBySeverity(!sortBySeverity);
  };

  // Handler for draft toggle change
  const handleShowDraftChange = () => {
    setShowDraft(!showDraft);
  };

  // Function to determine sorted cases based on date or severity toggle
  const getSortedCases = () => {
    const currentCases = showDraft ? draft : cases;
    if (sortBySeverity) {
      return sortCasesBySeverity(currentCases || []);
    } else {
      return sortCasesByDate(currentCases || []);
    }
  };

  return (
    <div className="body">
      <div className="titlebar">
        <div className="Title">Welcome, {user}!</div>
        <div className="actions">
          <div className="toggleGRP">
            <ThemeProvider theme={theme}>
              <Tooltip title="Sort Cases by Date or Severity" placement="top" arrow>
                <div className="togglespace" onClick={handleSortToggleChange}>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    label="Date and Severity"
                  />
                  <SwapVertIcon className="sortHover" />
                </div>
              </Tooltip>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Tooltip title="Show Draft Cases" placement="top" arrow>
                <div className="togglespace" onClick={handleShowDraftChange}>
                  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label="Draft" />
                </div>
              </Tooltip>
            </ThemeProvider>
          </div>
          <div className="actionGRP">
            {/* <Button style="Alt-Ghost" icon="snapshot" />
            <Button style="Alt-Ghost" icon="close" /> */}
            <div style={{ border: "none", padding: "0" }} onClick={() => navigate('/grid-dash')}>
              <Button style="Alt-Ghost" icon='grid' />
            </div>
            <div style={{ border: "none", padding: "0" }} onClick={() => navigate('/add-new-case')}><Button Label="Add New Case" style="Default" icon="plus" /></div>
          </div>
        </div>
      </div>
      <>
        {cases ? <CaseList jsonData={{ cases: getSortedCases() }} /> : <LoadingCard Message={"Almost Done"} Helper={"Please wait while we set up your dashboard."} />}
      </>
    </div>
  )
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4fbc9c',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default ListDash;
