import "../CSS/SingleDropdown.css";
import { useRef, useState, useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { OutcomePlanData }  from './outcomeplantypes'; 

interface DropSelectProps {
    selectedValue?: string;
    LabelName: string;
    HelperText?: string;
    Required: boolean;
    Helper: boolean;
    ListItems: OutcomePlanData[];
    onSelect: (value: string) => void;
    getItemValue: (item: OutcomePlanData) => string;
}

export const OutcomeCare: React.FC<DropSelectProps> = ({
    selectedValue,
    LabelName,
    HelperText,
    Required,
    Helper,
    ListItems,
    onSelect
}) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(selectedValue || '');
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = () => {
        setOpen(!open);
    };

    const handleClickOutsideDropdown = (e: MouseEvent) => {
        if (open && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    const handleItemSelect = (item: OutcomePlanData) => {
        const selectedItemInfo = JSON.stringify({
            name: item.name,
            outcomePlanId: item.outcomePlanId,
            versionNumber: item.versionedOutcomePlanId.versionNumber
        });
        setSelected(`${item.name} - Version ${item.versionedOutcomePlanId.versionNumber}`);
        setOpen(false);
        onSelect(selectedItemInfo);
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutsideDropdown);
        return () => window.removeEventListener("click", handleClickOutsideDropdown);
    }, [open]);

    return (
        <div className="dropContainer" ref={dropdownRef}>
            <div className="labelName">
                <div className="Label">{LabelName}</div>
                {Required && (
                    <div className="Required">
                        <div className="reqText">Required</div>
                        <div className="asterisk">*</div>
                    </div>
                )}
            </div>
            <div className="DropSelector">
                <button className="DropSelect" onClick={handleDropdownToggle}>
                    {selected || "Select an option"}
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </button>
            </div>
            {open && (
                <div className="ItemSelector">
                    {ListItems.map((item, index) => (
                        <button 
                            className="ItemList" 
                            key={index}
                            onClick={() => handleItemSelect(item)}
                        >
                            {item.name} - Version {item.versionedOutcomePlanId.versionNumber}
                        </button>
                    ))}
                </div>
            )}
            {Helper && <div className="single-drop-helper">{HelperText}</div>}
        </div>
    );
};

export default OutcomeCare;
