import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
  } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react';
import App from './Auth/Auth';
import { msalConfig } from './Auth/authConfig';
import { store } from './store/store'; // Import your Redux store

const msalInstance = new PublicClientApplication(msalConfig)
const accounts = msalInstance.getAllAccounts();

msalInstance.initialize().then(() => {
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0])
    }
  
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)
      }
    })
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <BrowserRouter>
              <Provider store={store}>
                <App />
              </Provider>
            </BrowserRouter>
          </MsalProvider>
        </React.StrictMode>,
      )
    })

    reportWebVitals();