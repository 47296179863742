const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || '', 
        authority: process.env.REACT_APP_AUTHORITY || '', 
        redirectUri: process.env.REACT_APP_REDIRECT_URI || '', 
        knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES || ''], 
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI || '',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage', 
        storeAuthStateInCookie: false, 
    },
};

const loginRequest = {
    scopes: ['openid', 'profile'],
    prompt: 'select_account',
};

const passwordResetRequest = {
    authority: process.env.REACT_APP_PASSWORD_RESET_AUTHORITY || '', 
    scopes: ['openid', 'profile'],
};

export { msalConfig, loginRequest, passwordResetRequest };
