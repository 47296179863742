import React, { useState } from 'react';
import '../CSS/AddNewCase.css';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';

type PhoneInputProps = {
    onChange: (value: string) => void;
    value: string;
};

export const PhoneInput: React.FC<PhoneInputProps> = ({
    onChange,
    value
}) => {
    const [error, setError] = useState<string>('');

    const formatPhoneNumber = (input: string) => {
        // Remove all non-digit characters from the input
        const cleaned = ('' + input).replace(/\D/g, '');
        // Match the cleaned string with the format (123) - 456 - 7890
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            const part1 = match[1];
            const part2 = match[2];
            const part3 = match[3];
            if (part2) {
                return ` (${part1}) ${part2}${part3 ? '-' + part3 : ''}`;
            } else if (part1) {
                return `(${part1}`;
            }
        }
        return input;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (/\D/.test(inputValue.replace(/[() -]/g, ''))) { // Check if non-digit characters are present
            setError('Please enter only numbers');
        } else {
            setError('');
            const formattedPhoneNumber = formatPhoneNumber(inputValue);
            onChange(formattedPhoneNumber);
        }
    };

    return (
        <div className="PatientInputLineContainer">
            <input 
                className="patientInputLine" 
                type="text"
                onChange={handleChange}
                value={value}
            />
            <div className="phone-inputIcon">
                <PhoneEnabledIcon />
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default PhoneInput;
