import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../CSS/Titlebar.css";
import "../CSS/CaseList.css";
import "../CSS/AddNewCase.css";
import "../CSS/Header.css";
import Button from "./Button";
import InformationModal from './InformationModal';
import { useMsal } from '@azure/msal-react';
import apiURL from "../API/requests";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface Props {
    organizationGroupId: string | null;
    outcomePlanId: string | null;
    providerId: string | null;
}

export interface ProviderWelcome {
    providerId: string;
    name: string;
    title: string;
    specialization: string;
    organization: Organization;
    state: string;
}

export interface Organization {
    organizationId: string;
    name: string;
    address: string;
    phoneNumber: string;
    preferredHours: string;
    greeting: string;
    schedulingMessage: string;
    organizationGroup: OrganizationGroup;
}

export interface OrganizationGroup {
    organizationGroupId: string;
    name: string;
    address: string;
    phoneNumber: string;
    milaPhoneNumber: string;
}

const Providers: React.FC<Props> = ({ organizationGroupId }) => {
    const navigate = useNavigate();
    const [data, setData] = useState<ProviderWelcome[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { instance, accounts } = useMsal();  
    const [isInfoModalOpen, setInfoModalOpen] = useState(false);
    const [infoResponse, setInfoResponse] = useState<any[]>([]);

    const openInfoModal = () => {
        setInfoModalOpen(true);
    };

    const closeInfoModal = () => {
        setInfoModalOpen(false);
    };

    const getAccessToken = async () => {
        if (accounts.length > 0) {
            try {
                const token = localStorage.getItem("token");
                return token;
            } catch (error) {
                const token = localStorage.getItem("token");
                return token;
            }
        }
        throw new Error('No accounts found');
    };

    useEffect(() => {
        const fetchData = async () => {    
            const url = `${apiURL.fetchProviders}${organizationGroupId}/providers?offset=0&limit=30`;
            const token = await getAccessToken();
          
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result: ProviderWelcome[] = await response.json();
                setData(result);
            } catch (error) {
                setError((error as Error).message);
            }
        };
    
        fetchData();
    }, []);
console.log("Proivder Data:", data)
    const handleInfoModalSubmit = (data: any) => {
        setInfoResponse((prevResponse) => {
            const updatedResponse = [...prevResponse, data];
            if (data.infoResponse) {
                navigate('/');
            }
            return updatedResponse;
        });
    };

    return (
        <div style={{ display: "block", marginBottom: "12px" }}>
            <div className="titlebar" style={{ marginBottom: "12px" }}>
                <div style={{display:"flex", flexDirection:"row", gap:"12px"}}>
                    <button onClick={() => navigate('/')} style={{border:"none", padding:"0"}}>
                        <Button style="Back" icon="back" />
                    </button> 
                    <div className="Title">Providers List ({data.length})</div>                  
                </div> 

                <div className="actions">
                    <div><Button Label="Add Provider" style="disabled" icon="plus"/></div>
                    <div onClick={openInfoModal}><Button Label="Cancel" style="Alt-Ghost" /></div>
                </div>
            </div>
            <div className="ListView">
                <div className="newcase-container" style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap:"12px"}}>
                    {data.map(provider => (
                        <div key={provider.providerId} className="newcase-carecard" style={{width:"24%"}}>
                            <div className="newcase-healthcase-head">
                            {provider.title}{provider.name}
                                <div><Button Label={provider.state} style={provider.state} icon={provider.state} /></div>
                            </div>
                            <div className="newcase-care-drop-body">
                                <div className="Provider-blockBody"> <b>Specialization</b> <ArrowRightIcon /> {provider.specialization} </div>
                                <div className="Provider-blockBody"> <b>Organization Name</b> <ArrowRightIcon /> {provider.organization.name} </div>                                
                                <div className="Provider-blockBody"> <b>Organization Address</b> <ArrowRightIcon /> {provider.organization.address} </div>
                                <div className="Provider-blockBody"> <b>Preferred Hours</b> <ArrowRightIcon /> {provider.organization.preferredHours} </div>
                                <div className="Provider-blockBody"> <b>Greeting</b> <ArrowRightIcon /> {provider.organization.greeting} </div>
                                <div className="Provider-blockBody"> <b>Scheduling Message</b> <ArrowRightIcon /> {provider.organization.schedulingMessage} </div>
                                <div className="Provider-blockBody"> <b>Organization Group Name</b> <ArrowRightIcon /> {provider.organization.organizationGroup.name} </div>
                                <div className="Provider-blockBody"> <b>Organization Group Address</b> <ArrowRightIcon /> {provider.organization.organizationGroup.address} </div>
                                <div className="Provider-blockBody"> <b>Specialization</b> <ArrowRightIcon /> {provider.specialization} </div>
                                <a href={`tel:${provider.organization.phoneNumber}`} style={{width: "100%"}}><Button Label={provider.organization.phoneNumber + " - Organization Phone"} style="Default" icon="phone" /></a>
                                <a href={`tel:${provider.organization.organizationGroup.phoneNumber}`} style={{width: "100%"}}><Button Label={provider.organization.organizationGroup.phoneNumber + " - Organization Group Phone"} style="Default" icon="phone" /></a>
                                <a href={`tel:${provider.organization.organizationGroup.milaPhoneNumber}`} style={{width: "100%"}}><Button Label={provider.organization.organizationGroup.milaPhoneNumber + " - Mila Phone Number"} style="Default" icon="phone" /></a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isInfoModalOpen && 
                <InformationModal 
                    InfoHead="Are you sure you want to cancel the form?"
                    InfoMessage="Upon confirmation, any data entered in the form will be lost."
                    onClose={closeInfoModal} 
                    onSubmit={handleInfoModalSubmit} 
                />
            }          
        </div>
    );
}

export default Providers;
