import "../CSS/SingleDropdown.css";
import "../CSS/CountriesDrop.css";
import { useRef, useState, useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type DropSelectValue<T> = {
    selectedValue?: string;
    LabelName: string;
    HelperText?: string;
    Required: boolean;
    Helper: boolean;
    ListItems: T[];
    getItemValue: (item: T) => string;
    onSelect: (value: string) => void; // New prop for handling selection
};

const getLastWord = (TheSentence: string): string => {
    let lastWord = TheSentence.substring(TheSentence.lastIndexOf(" ") + 1);
    return lastWord;
};

export const CountriesDrop = <T extends unknown>(props: DropSelectValue<T>) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>(props.selectedValue || '');
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = () => {
        setOpen(!open);
    };

    const handleClickOutsideDropdown = (e: MouseEvent) => {
        if (open && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    const handleItemSelect = (value: string) => {
        setSelectedValue(value);
        setOpen(false);
        props.onSelect(value); // Notify parent component about the selection
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutsideDropdown);
        return () => {
            window.removeEventListener("click", handleClickOutsideDropdown);
        };
    }, [open]);

    return (
        <div className="country-dropContainer">            
            <div className="phonecountrycode" ref={dropdownRef}>
                <button className="CountryDropSelect" onClick={handleDropdownToggle}>
                    {getLastWord(selectedValue) || "Select an option"}
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </button>
            </div>
            
            {open && (
                <div className="country-ItemSelector">
                    {props.ListItems.map((item, index) => (
                        <button 
                            className="country-ItemList" 
                            key={index} 
                            value={props.getItemValue(item)}
                            onClick={() => handleItemSelect(props.getItemValue(item))}
                        >
                            {props.getItemValue(item)}
                        </button>
                    ))}
                </div>
            )}
            
            {props.Helper && <div className="single-drop-helper">{props.HelperText}</div>}
        </div>
    );
};

export default CountriesDrop;
