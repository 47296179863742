import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilePic from "../Images/ProfileIcon.png";
import Edit from "../Images/edit.png";
import "../CSS/ProfileDropdown.css";
import Button from "../Components/Button";
import { useMsal } from '@azure/msal-react';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

type DropElements = {
  UserName: string;
  Designation: string;
};

export const ProfileDropdownMenu = (props: DropElements) => {
  const { instance } = useMsal();
  const [idToken, setIdToken] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await instance.logoutRedirect();
    setIdToken("");
    setIsDropdownOpen(false);
  };

  const handleManageProvider = () => {
    navigate('/providers');
    setIsDropdownOpen(false);
  };

  const handleManageOrganiser = () => {
    navigate('/organisers');
    setIsDropdownOpen(false);
  };  

  if (!isDropdownOpen) return null;

  return (
    <div className="userdrop">
      <div className="nameCard">
        <img className="ProfilePic" alt="Profile" src={ProfilePic} />
        <img className="EditIcon" alt="Edit" src={Edit} />
        <div className="NameSection">
          <b className="UserName">{props.UserName}</b>
          <div className="UserDesignation">{props.Designation}</div>
        </div>
      </div>
      <div className="rectangleDiv" />
      <div className="manageSection">
        <div onClick={handleManageProvider}>
          <Button Label="Manage Provider" style="Default" icon="right" />
        </div>
        <div onClick={handleManageOrganiser}>
          <Button Label="Manage Clinic" style="Default" icon="right" />
        </div>
        <button className="Alt" onClick={handleLogout}>
          Logout <PowerSettingsNewIcon className="transp" />
        </button>
      </div>
    </div>
  );
};

export default ProfileDropdownMenu;
