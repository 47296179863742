import Loading from "../Images/loading.gif";
import "../CSS/EmptyCard.css";

type LoadProps = {
    Message: string
    Helper: string
  }

export const EmptyCard = (props: LoadProps) => {
  return (

      <div className="EmptyCard"  style={{background: "white", overflow:"hidden"}}>
          <img className="PersonNote" src={Loading} alt="Logo" style={{width: "10%"}} />
          <div className="Heading2">
            {props.Message}
          </div>
          <div className="helper">
            {props.Helper}
          </div>
        </div>

    
  );
}
export default EmptyCard;
